import React, { Component } from 'react';
import uuid from 'uuid/v4';
import $ from 'jquery';


class CustomImage extends Component {
  constructor (props) {
    super(props);

    this.file_input_id_ = this.props.field + '_fileinput';
  }

  componentDidMount () {
  }

  render () {
    return (
      <div className={this.props.className} >
        <img
          className={this.props.imgClassName}
          src={this.props.src}
          style={{
            width : this.props.width,
            height: this.props.height,
          }}
        />
        <button
          className="ml-3 btn btn-sm btn-primary"
          onClick={(e)=>this._onClick(e)}
        >
          업로드
        </button>
        <input
          className="d-none"
          type="file"
          id={this.file_input_id_}
          accept="image/jpg, image/jpeg, image/png"
          onChange={(e)=>this._onFileChange(e)}
        />
      </div>
    );
  }

  _onClick (ev) {
    $('#'+this.file_input_id_).click();
  }

  _onFileChange(ev) {
    const files = ev.target.files;
    if (files === undefined || files.length <= 0)
      return;

    var reader = new FileReader();
    reader.onload = (e) => {
      // $('#'+this.img_id_).attr('src', e.target.result);
      this.props.onChange(e.target.result);
    };
    reader.readAsDataURL(files[0]);
    
  }
}


CustomImage.defaultProps = {
  field: '',
  className: '',
  imgClassName: 'rounded',
  src: '',
  width: '10rem',
  height: '10rem',
  onChange: (src) => {},
};

export default CustomImage;

