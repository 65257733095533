import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import LoadingText from './LoadingText';
import NoValueText from './NoValueText';


class DataTable extends Component {
	render () {
		return (
      <table className="table table-sm table-hover text-nowrap">
        <thead className="thead-dark">
          <tr>
            {this._renderFields(this.props.fields)}
          </tr>
        </thead>
        <tbody>
          {this._renderRows(this.props.rows)}
        </tbody>
      </table>
		);
  }
  
  
	_renderFields (fields) {
		const result = [];
    if (this.props.editPath !== '')
      result.push(<th key='edit' scope="col" className="px-3"></th>);

    const heads = this.props.headMaker(null, null);
    for (const hi in heads)
      result.push(<th key={`head.${hi}`} scope="col" className="px-3">{heads[hi]}</th>);

		for (const f of fields)
      result.push(<th key={f} scope="col" className="px-3">{f}</th>);

    const tails = this.props.tailMaker(null, null);
    for (const ti in tails)
      result.push(<th key={`tail.${ti}`} scope="col" className="px-3">{tails[ti]}</th>);
    
    
		return result;
  }
  
  _renderRows (rows) {
    if (rows === undefined)
      return (<tr><td><div className="m-5"><LoadingText /></div></td></tr>);
    else if (rows === null)
      return (<tr><td><div className="m-5"><NoValueText /></div></td></tr>);


    const result = [];
    for (const ri in rows) {
      const r = rows[ri];
      const tds = [];
      const key = this.props.keyMaker(ri, r);
      if (this.props.editPath !== '')
        tds.push(<td key={`${key}.edit`} className="px-3"><Link to={this.props.editPath + '?id='+r[0]}>edit</Link></td>);
            
      const heads = this.props.headMaker(ri, r);
      for (const hi in heads) {
        const h = heads[hi];
        tds.push(<td key={`${key}.head.${hi}`}>{h}</td>);
      }

      for (const vi in r) {
        let v = r[vi];

        let class_name = 'px-3';
        if (v === true || v === 'true')
          class_name += ' text-success';
        else if (v === false || v === 'false')
          class_name += ' text-danger';

        tds.push(<td key={key + '.' + vi} className={class_name}>{v}</td>);
      }

      const tails = this.props.tailMaker(ri, r);
      for (const ti in tails) {
        const t = tails[ti];
        tds.push(<td key={`${key}.tail.${ti}`}>{t}</td>);
      }

      result.push(<tr key={key}>{tds}</tr>);
    }
    return result;
  }
}

DataTable.defaultProps = {
  editPath: '/store/edit',
  headMaker: (ri, row)=>{return []},
  tailMaker: (ri, row)=>{return []},
  keyMaker : (ri, row)=>ri,
  fields: [],
  rows: undefined,  // [[...], [...], ...]
};

export default DataTable;