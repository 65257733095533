

class MountChecker {
  constructor () {
    this.is_mounted_ = false;
  }

  onComponentDidMount () {
    this.is_mounted_ = true;
  }

  onComponentWillUnmount () {
    this.is_mounted_ = false;
  }

  isMounted () {
    return this.is_mounted_;
  }

  throwWhenUnmounted () {
    if (this.is_mounted_ === false)
      throw {name: 'MountChecker Exception', message: 'component is unmounted'};
  }
}

export default MountChecker;