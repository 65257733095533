



export default {
  makeCommaedString (value, count_post_point = 2) {
    if (typeof(value) === 'string')
      value = parseFloat(value);
    if (Number.isFinite(value) === false)
      return value;
    if (Number.isNaN(value) || value === undefined || value === null)
      return value;
    
    let sign = '';
    if (value < 0) {
      sign = '-';
      value *= -1;
    }
  
    const pow_10_cnt = Math.pow(10, count_post_point);
    value = Math.round(value * pow_10_cnt) / pow_10_cnt;
  
    let post_point_str = '';
    if (count_post_point > 0) {
      post_point_str = '.';
      const splited = value.toString().split('.');
      if (splited.length >= 2)
        post_point_str += splited[1];
      
      for (let i=post_point_str.length; i<=count_post_point; ++i)
        post_point_str += '0';
    }

    value = Math.floor(value);
  
  
    const numbers = [];
    if (value === 0)
      numbers.push('0');

    while (value > 0) {
      numbers.push(value % 1000);
      value = Math.floor(value / 1000);
    }
    numbers.reverse();
  
    const str_numbers = numbers.map((v, idx) => {
      if (idx === 0)
        return '' + v;
      
      if (v < 10)
        return '00' + v;
      else if (v < 100)
        return '0' + v;
      else
        return '' + v;
    });
  
    return sign + str_numbers.join(',') + post_point_str;
  },

  removeTails (str, chrs_to_del) {
    let idx = str.length -1;
    for (; idx >= 0; --idx) {
      if (chrs_to_del.indexOf(str[idx]) < 0)
        break;
    }
    return str.substr(0, idx + 1);
  },

  makeYmdHms (dt) {
    return `${this.makeYmd(dt)} ${this.makeHms(dt)}`;
  },

  makeYmd (dt) {
    if (typeof dt === 'string')
      dt = new Date(dt);

    const year  = dt.getFullYear();
    const month = (dt.getMonth()+1).toString().replace(/^\b(?=\d$)/, '0');
    const date   = dt.getDate().toString().replace(/^\b(?=\d$)/, '0');

    return  year + '-' + month + '-' +  date;  // YYYY-MM-DD
  },
  
  makeHms (dt) {
    if (typeof dt === 'string')
      dt = new Date(dt);

    const h = dt.getHours  ().toString().replace(/^\b(?=\d$)/, '0');
    const m = dt.getMinutes().toString().replace(/^\b(?=\d$)/, '0');
    const s = dt.getSeconds().toString().replace(/^\b(?=\d$)/, '0');

    return  [h, m, s].join(':');
  },
}
