import React, { Component } from 'react';
import $                    from 'jquery';

import AdminTemplateEngine from '../../util/admin_template_engine';
import common              from '../../util/common';
import MountChecker        from '../../util/mount_checker';
import server_api          from '../../util/server_api';
import TableEdit           from '../common/TableEdit';
import user_data           from './user_data_format';


class AdministratorEdit extends Component {
	constructor(props) {
		super(props);
		
		this.ate_ = new AdminTemplateEngine(
      user_data.data_format,
      {},
			user_data.lang_dic,
		);

		this.mount_checker_ = new MountChecker();
	}

	componentDidMount () {
		this.mount_checker_.onComponentDidMount();
	}

	componentWillUnmount () {
		this.mount_checker_.onComponentWillUnmount();
	}

	render () {
		return (
			<TableEdit
				redirectPath="/user"
				adminTemplateEngine={this.ate_}
				needDel={false}
        fetchFunc  ={server_api.fetchUsers}
				createFunc ={(params, options)=>this._register(params, options)}
				updateFunc ={server_api.updateUser}
				deleteFunc ={server_api.deleteUser}
				addTitle    ="추가"
				confirmTitle="확정"
				deleteTitle ="삭제"
			/>
		);
	}
	
	async _register (params, options) {
		return await server_api.registerUser(params);
	}
}

export default AdministratorEdit;