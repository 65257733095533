import AdminTemplateEngine from "../../util/admin_template_engine";
import country_codes from '../../util/country_codes';

export default {
  data_format: {
    id          : { type: 'int'     , is_readonly: true, },
    country_code  : Object.keys(country_codes.kr).map((cc) => {
      return {
        present: `${cc}: ${country_codes.kr[cc]}`,
        value  : cc,
      };
    }),
    swift_code  : { type: 'string'  , validate: AdminTemplateEngine.validateNotEmpty, },
    name        : { type: 'string'  , validate: AdminTemplateEngine.validateNotEmpty, },
    status      : ['AVAILABLE', 'UNAVAILABLE'],
  },

  lang_dic: {
    country_code: '국가 코드',
    swift_code: 'swift 코드',
    name: '이름',
    status: '상태',
  },
}
