import React from 'react';

import AdminTemplateEngine from "../../util/admin_template_engine";
import string_util from "../../util/string_util";

export default {
  data_format: {
    id      : { type: "int", is_readonly: true, },
    created : {
      type: 'view',
      view: (datum, user_data) => '',
      present : (datum, user_data) => {
        const dt = new Date(datum.createdAt);
        return string_util.makeYmdHms(dt);
      },
      onChange: (datum, value, user_data)=>{},
    },
    user_id : { type: 'int', is_hidden: true, },
    user    : {
      type: 'view',
      view: (datum, user_data) => '',
      present : (datum, user_data) => {
        return `${datum.user.id}: ${datum.user.login_id}`;
      },
      onChange: (datum, value, user_data)=>{},
    },
    exchanger_id: { type: 'int', is_hidden: true, },
    exchanger   : {
      type: 'view',
      view: (datum, user_data) => '',
      present : (datum, user_data) => {
        return `${datum.exchanger.id}: ${datum.exchanger.name}`;
      },
      onChange: (datum, value, user_data)=>{},
    },
    from: {
      type: 'view',
      view: (datum, user_data) => '',
      present : (datum, user_data) => {
        return `${string_util.makeCommaedString(datum.from_token_amount, 4).replace(/\./, ' .')} ${datum.from_token_symbol}`;
      },
      onChange: (datum, value, user_data)=>{},
    },
    to: {
      type: 'view',
      view: (datum, user_data) => '',
      present : (datum, user_data) => {
        return `${string_util.makeCommaedString(datum.to_token_amount, 4).replace(/\./, ' .')} ${datum.to_token_symbol}`;
      },
      onChange: (datum, value, user_data)=>{},
    },
    commission_rate  : { type: 'commaed.2', },
    commission_amount: { type: 'commaed.4', },
  },

  lang_dic: {
    created: '작성일',
    exchange_type: '환전 방식',
    user: '유저',
    exchanger: '지점',
    from: '코인량(전)',
    to: '코인량(후)',
    commission_rate: '수수료율',
    commission_amount: '수수료',
  },
}
