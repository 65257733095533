import AdminTemplateEngine from "../../util/admin_template_engine";
import common from '../../util/common';
import country_codes from '../../util/country_codes';

export default {
  data_format: {
    id             : { type: 'int'     , is_readonly: true, },
    parent_admin_id: { type: 'int'     , is_hidden: true, default: (user_data)=>common.id,},
    parent_admin: {
      type    : "view",
      view    : (datum, user_data) => {
        return user_data.admins.map((a)=>{
          return {
            present: _makeAdminName(a),
            value: JSON.stringify(a),
          };
        });
      },
      present : (datum, user_data) => {
        if (datum.parent_admin === undefined) {
          const selected = user_data.admins.filter((b)=>b.id === datum.parent_admin_id)[0];
          if (selected !== undefined)
            datum.parent_admin = JSON.stringify(selected);
          // else
          //   datum.parent_admin = JSON.stringify(user_data.admins[0]);
        }

        if (datum.parent_admin === undefined)
          return '';

        return _makeAdminName(datum.parent_admin);
      },
      onChange: (datum, value, user_data) => {
        datum.parent_admin = value;
        datum.parent_admin_id = JSON.parse(value).id;
      },
      is_hidden: (user_data) => {
        return (common.role === 'SUPERUSER')? false: true;
      },
      default: (user_data) => {
        return user_data.admins.filter((a)=>a.id === common.id)[0];
      },
    },
    login_id       : { type: 'string'  , validate: AdminTemplateEngine.validateNotEmpty, },
    password       : { type: 'password', is_hidden_in_list: true, deafult: 'password' },
    eos_account    : { type: 'string'  , is_readonly: true, },
    eos_active_pub : { type: 'string'  , is_readonly: true, },
    name           : { type: 'string'  , validate: AdminTemplateEngine.validateNotEmpty, },
    country_code   : Object.keys(country_codes.kr).map((cc) => {
      return {
        present: `${cc}: ${country_codes.kr[cc]}`,
        value  : cc,
      };
    }),
    latitude      : { type: 'commaed.6', validate: AdminTemplateEngine.validateNotEmpty, },
    longitude     : { type: 'commaed.6', validate: AdminTemplateEngine.validateNotEmpty, },
    phone_numbers : { type: 'string'   , validate: AdminTemplateEngine.validateNotEmpty, },
    address       : { type: 'string'   , validate: AdminTemplateEngine.validateNotEmpty, },
  },

  lang_dic: {
    parent_admin: '관리자',
    login_id: '로그인 아이디',
    password: '패스워드',
    eos_account: 'eos 계정',
    eos_active_pub: 'eos active pub',
    name: '이름',
    country_code: '국가 코드',
    latitude: '위도',
    longitude: '경도',
    phone_numbers: '전화번호',
    address: '주소',
  },
}


function _makeAdminName (admin) {
  if (typeof admin === 'string')
    admin = JSON.parse(admin);
  return `${admin.id}: ${admin.name}(${admin.country_code})`;
}