import React, { Component } from "react";
import { Link } from "react-router-dom";

import common from "../util/common";

class TopMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menus: [
        { name: "관리자", path: "/administrator" },
        // { name: '지점'          , path: '/exchanger'                },
        { name: "유저", path: "/user" },
        { name: "지갑계정", path: "/account" },
        // { name: '충전'          , path: '/money-to-token'           },
        // { name: "화폐 환전", path: "/token-to-money" },
        { name: "코인 환전", path: "/coin-exchange" },
        // { name: "이체", path: "/transfer" },
        // { name: '정산'                                              },
        {
          name: "화폐 환전",
          path: "/calculate/token-to-money",
          parent: "정산",
        },
        {
          name: "코인 환전",
          path: "/calculate/token-to-token",
          parent: "정산",
        },
        // { name: '통계'          , path: '/statistics'               },
        // { name: '은행'          , path: '/bank'                     , available_roles: ['SUPERUSER']},
        // { name: '게시판'                                            , available_roles: ['SUPERUSER']},
        // { name: '공지사항'      , path: '/notice'                   , available_roles: ['SUPERUSER'], parent: '게시판'},
        // { name: 'FAQ'           , path: '/faq'                      , available_roles: ['SUPERUSER'], parent: '게시판'},
        { name: "설정", available_roles: ["SUPERUSER"] },
        {
          name: "전역설정 값",
          path: "/global-vars",
          available_roles: ["SUPERUSER"],
          parent: "설정",
        },
        {
          name: "Superuser 이체",
          path: "/superuser-transfer",
          available_roles: ["SUPERUSER"],
          parent: "설정",
        },
        // { name: 'Contract'        , path: '/contract'                    },
        // { name: 'Model'           , path: '/model'                       },
        // { name: 'Item'            , path: '/item'                        },
        // { name: 'Posting'         , path: '/posting'                     },
        // { name: '프로젝트 관리자' , path: '/project-manager'             },
        // { name: '스케쥴 관리자'   , path: '/schedule-manager'            },
        // { name: '모델 등업 관리자',                                      },
        // { name: '지원자 (신입)'   , path: '/model-rankup-manager/newbie' , parent: '모델 등업 관리자'},
        // { name: '지원자 (대기)'   , path: '/model-rankup-manager/standby', parent: '모델 등업 관리자'},
        // { name: '위반사항 관리자' , path: '/warning-manager'             },
        // { name: '직원',                                                  },
        // { name: '웹 관리자'       , path: '/administrator'               , parent: '직원'},
        // { name: '배송자'          , path: '/deliverer'                   , parent: '직원'},
      ],
    };
  }

  _logout() {
    common.logout();
    this.props.history.push("/login");
  }

  render() {
    let cur_path = window.location.pathname;
    if (cur_path === "/") cur_path = "/token";

    const menu_tags = this.state.menus
      .filter((m) => m.parent === undefined)
      .map((menu) => {
        // check available
        if (
          menu.available_roles &&
          menu.available_roles.indexOf(common.role) < 0
        )
          return null;

        // simple menu
        if (menu.path !== undefined) {
          return (
            <Link
              key={menu.name}
              className={
                "nav-item nav-link mx-3" +
                (cur_path === menu.path ? " active" : "")
              }
              to={menu.path}
            >
              {menu.name}
            </Link>
          );
        }

        // has sub menu
        // make sub menus
        const submenus = this.state.menus.filter((m) => m.parent === menu.name);
        const is_selected =
          submenus.filter((sm) => sm.path === cur_path)[0] !== undefined;
        const submenu_jsxes = submenus.map((sm) => {
          return (
            <Link
              key={sm.name}
              className={
                "dropdown-item" +
                (cur_path === sm.path ? " bg-secondary text-white" : "")
              }
              to={sm.path}
            >
              {sm.name}
            </Link>
          );
        });

        return (
          <div key={menu.name} className="dropdown">
            <Link
              className={
                "nav-item nav-link mx-3 dropdown-toggle" +
                (is_selected ? " active" : "")
              }
              id={`menu.${menu.name}`}
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              to=""
            >
              {menu.name}
            </Link>

            <div
              className="dropdown-menu"
              aria-labelledby={`menu.${menu.name}`}
            >
              {submenu_jsxes}
            </div>
          </div>
        );
      });

    return (
      <div id="nav" className="px-0">
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark w-100">
          <Link className="navbar-brand" to="/">
            힘월렛 관리자
          </Link>
          <div className="navbar-nav">{menu_tags}</div>
          <div className="navbar-nav ml-auto">
            <button
              className="btn btn-sm btn-secondary"
              onClick={() => this._logout()}
            >
              로그아웃
            </button>{" "}
            {/* TODO if common._logout() */}
          </div>
        </nav>
      </div>
    );
  }
}

export default TopMenu;
