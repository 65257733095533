import React from 'react';

import AdminTemplateEngine from "../../util/admin_template_engine";
import string_util from "../../util/string_util";

export default {
  data_format: {
    id      : { type: "int", is_readonly: true, },
    created : {
      type: 'view',
      view: (datum, user_data) => '',
      present : (datum, user_data) => {
        const dt = new Date(datum.createdAt);
        return string_util.makeYmdHms(dt);
      },
      onChange: (datum, value, user_data)=>{},
    },
    user_id : { type: 'int', is_hidden: true, },
    user    : {
      type: 'view',
      view: (datum, user_data) => '',
      present : (datum, user_data) => {
        return `${datum.user.id}: ${datum.user.login_id}`;
      },
      onChange: (datum, value, user_data)=>{},
    },
    user_bankaccount_id: { type: 'int', is_hidden: true, },
    user_bank_account   : {
      type: 'view',
      view: (datum, user_data) => '',
      present : (datum, user_data) => {
        const ub = datum.user_bankaccount;
        return (
          <div>
            <div>{`${ub.id}: [${ub.bank.country_code}] ${ub.bank.name}`}</div>
            <div>{`${ub.bank_account} ${ub.owner_name}`}</div>
          </div>
        );
      },
      onChange: (datum, value, user_data)=>{},
    },
    exchanger_id: { type: 'int', is_hidden: true, },
    exchanger   : {
      type: 'view',
      view: (datum, user_data) => '',
      present : (datum, user_data) => {
        return `${datum.exchanger.id}: ${datum.exchanger.name}`;
      },
      onChange: (datum, value, user_data)=>{},
    },
    exchanger_bankaccount_id: { type: 'int', is_hidden: true, },
    exchanger_bank_account   : {
      type: 'view',
      view: (datum, user_data) => '',
      present : (datum, user_data) => {
        const eb = datum.exchanger_bankaccount;
        return (
          <div>
            <div>{`${eb.id}: [${eb.bank.country_code}] ${eb.bank.name}`}</div>
            <div>{`${eb.bank_account} ${eb.owner_name}`}</div>
          </div>
        );
      },
      onChange: (datum, value, user_data)=>{},
    },
    from: {
      type: 'view',
      view: (datum, user_data) => '',
      present : (datum, user_data) => {
        return `${string_util.makeCommaedString(datum.money_amount, 4).replace(/\./, ' .')} ${datum.money_currency_symbol}`;
      },
      onChange: (datum, value, user_data)=>{},
    },
    to: {
      type: 'view',
      view: (datum, user_data) => '',
      present : (datum, user_data) => {
        return `${string_util.makeCommaedString(datum.token_amount, 4).replace(/\./, ' .')} ${datum.token_symbol}`;
      },
      onChange: (datum, value, user_data)=>{},
    },
  },

  lang_dic: {
    created: '작성일',
    user: '유저',
    user_bank_account: '유저 계좌',
    exchanger: '지점',
    exchanger_bank_account: '지점 계좌',
    from: '화폐량',
    to: '코인량',
  },
}
