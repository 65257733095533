export default {
  data_format: {
    id: { type: "int", is_readonly: true },
    user_id: { type: "int", is_readonly: true },
    currency: { type: "string", is_readonly: true },
    amount: { type: "string" },
    eos_account: { type: "string", is_readonly: true },
    is_lock: { type: "boolean" },
    lock_percent: { type: "string" },
    lock_amount: { type: "string", is_readonly: true },
    lock_transfer_amount: { type: "string", is_readonly: true },
  },
  lang_dic: {
    user_id: "지갑사용자 아이디(유저에서 검색)",
    currency: "코인명",
    amount: "코인수량",
    eos_account: "주소",
    is_lock: "수량 락",
    lock_percent: "락 퍼센트",
    lock_amount: "락 금액",
    lock_transfer_amount: "락송금금액",
  },
};
