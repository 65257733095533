import React, {Component} from 'react';
import { Redirect } from 'react-router-dom'

import common       from '../../util/common';
import MountChecker from '../../util/mount_checker';

import './Login.css';


class Login extends Component {
	constructor(props) {
		super(props);

		this.state = {
			login_id: '',
			password: '',

			err_messages: [],
		}

		this.mount_checker_ = new MountChecker();
	}

	componentDidMount () {
		this.mount_checker_.onComponentDidMount();
	}

	componentWillMount () {
		this.mount_checker_.onComponentWillUnmount();
	}

	render () {
		if (common.isLoggedIn() === true) {
			return (
				<Redirect to='/' />
			);
		}

		return (
			<div className="w-100 h-100 d-flex justify-content-center align-items-center">
				<div className="w-100">
					<div className="mb-3 d-flex justify-content-center">
						<h2>힘월렛 관리자</h2>
					</div>
					<div className="d-flex justify-content-center">
						<div className="mr-1">
							<input
								type="text"
								className="form-control"
								placeholder="ID"
								style={{width:'13rem'}}
								value={this.state.login_id}
								onChange={(e)=>this._onChangeInput('login_id', e)}
							/>
							<input
								type="password"
								className="mt-1 form-control"
								placeholder="Password"
								value={this.state.password}
								onChange={(e)=>this._onChangeInput('password', e)}
							/>
						</div>
						<button type="button" className="px-3 btn btn-dark" onClick={()=>this._onSubmit()}>로그인</button>
					</div>
					<div className={"mt-4 w-100 alert alert-dark m-0 " + ((this.state.err_messages.length > 0) ? "visible" : "invisible")}>
						<div className="d-flex justify-content-center">
							<div>
								{this.state.err_messages.map((msg,idx)=>(<div key={idx}><small>{msg}</small></div>))}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	_onChangeInput (key, e) {
		const new_state = {};
		new_state[key] = e.target.value;
		this.setState(new_state);
	}

	async _onSubmit () {
		let check_account = false;
		const arr = this.state.account_list;

		let {login_id, password} = this.state;
		login_id = login_id.trim();

		let err_messages = [];
		if (login_id === '')
			err_messages.push('ID를 입력해 주세요');
		
		if (password === '')
			err_messages.push('Password를 입력해 주세요');
		
		if (err_messages.length > 0)
			return this.setState({ err_messages, });

		
		const status = await common.login(login_id, password);
		switch (status) {
			case 200: break;
			case 400: err_messages.push('ID, Password가 일치하지 않습니다'); break;
			case 401: err_messages.push('권한이 없습니다. 관리자에게 문의하세요'); break;
			default : err_messages.push('알수 없는 오류. 개발자에게 문의하세요'); break;
		}

		this.mount_checker_.throwWhenUnmounted();
		
		return this.setState({ err_messages, });
	}
}

export default Login;