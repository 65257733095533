import React, { Component } from 'react';
import string_util from '../util/string_util';


class CommaedNumberInput extends Component {
  constructor (props) {
    super(props);
    
    this.state = {
      is_changing: false,
      value: string_util.makeCommaedString(this._toNumber(props.value), this.props.underPointCount),
    }
  }

  componentWillUpdate (nextProps, nextState) {
    
    if (nextState.is_changing === true) {
      nextState.is_changing = false;
      return;
    }

    const value = string_util.makeCommaedString(this._toNumber(nextProps.value), nextProps.underPointCount);
    nextState.value = value;
  }

	render () {
		return (
      <div className={this.props.className + ' d-flex'} style={this.props.style}>
        <input
          type="text"
          className="form-control flex-grow-1"
          value={this.state.value}
          readOnly={this.props.readOnly}
          onFocus={(e)=>this._onFocus(e)}
          onBlur={(e)=>this._onBlur(e)}
          onChange={(e)=>this._onChange(e)}
        />
      </div>
		);
  }

  _onBlur (e) {
    this.setState({
      value: string_util.makeCommaedString(this._toNumber(this.state.value), this.props.underPointCount),
      is_changing: true,
    });
  }

  _onFocus (e) {
    this.setState({
      value: this._toNumber(this.state.value),
      is_changing: true,
    });
  }

  _onChange (e) {
    const value = e.target.value;
    this.props.onChange(this._toNumber(value));
    this.setState({
      value,
      is_changing: true,
    });
  }

  _toNumber (v) {
    try {
      v = v.toString();
      if (v[v.length - 1] === '.')
        v += '0';
      const value = parseFloat(v.replace(/,/g, ''));
      if (isNaN(value) === true)
        return 0;
      return value;
    }catch (e) {
      return 0;
    }
  }

}

CommaedNumberInput.defaultProps = {
  className: '',
  value: 0,
  underPointCount: undefined,
  readOnly: false,
  style: {},
  onChange: (v)=>{},
};

export default CommaedNumberInput;