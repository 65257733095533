import React, {Component} from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import string_util from "./string_util";
import SearchableDropdown from '../snippets/SearchableDropdown';
import UuidInput from '../snippets/UuidInput';
import CommaedNumberInput from '../snippets/CommaedNumberInput';
import CustomDatePicker from './ate_snippets/CustomDatePicker';
import CustomImage from './ate_snippets/CustomImage';
import LoadingText from '../snippets/LoadingText';
import NoValueText from '../snippets/NoValueText';


const MAX_LEN_OF_OBJ = 40;

class AdminTemplateEngine {
  static makeValidateForKeys (keys, validateFunc) {
    return (datum, user_data, key, value, setValue) => {
      let result = [];
      for (const k of keys)
        result = result.concat(validateFunc(datum, user_data, k, value, setValue));
      
      return result;
    };
  }

  static validateNotEmpty (datum, user_data, key, value, setValue) {
    const err = [[key, ' : 비어있습니다']];
    if (value === undefined || value === null)
      return err;
    
    value = value.toString().trim();
    if (value === '')
      return err;

    return [];
  }
  
  static validateEmail (datum, user_data, key, value, setValue) {
    if (value === undefined || value === null)
      return [[key, ' : 비어있습니다']];
    
    value = value.toString().trim();
    if (value === '')
      return [[key, ' : 비어있습니다']];

    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const is_ok = re.test(String(value).toLowerCase());

    if (is_ok === true)
      return [];
    
    return [[key, ' : 이메일 형식이 아닙니다']];
  }
  
  static validateJson (datum, user_data, key, value, setValue=(v)=>{}) {
    if (typeof value === 'object')
      return [];

    let json_data;
    try {
      json_data = JSON.parse(value);
      if (typeof json_data !== 'object')
        return [[key, ' : 올바른 JSON 형식이 아닙니다']];
    }catch (e) {
      const msg = e.message;

      const splited = msg.split(' ');
      const pos = parseInt(splited[splited.length - 1]);

      const line = value.slice(0, pos).split('\n').length;
      const result = msg.slice(0, msg.indexOf('position')) + 'line ' + line;

      return [[key, ' : 올바른 JSON 형식이 아닙니다 ' + result]];
    }
    
    setValue(json_data);
    return [];
  }
  
  static validateJsonArray (datum, user_data, key, value, setValue=(v)=>{}) {
    if (Array.isArray(value) === true)
      return [];

    let json_data;
    try {
      json_data = JSON.parse(value);
      if (Array.isArray(json_data) !== true)
        return [[key, ' : 올바른 Array 형식이 아닙니다']];
    }catch (e) {
      const msg = e.message;

      const splited = msg.split(' ');
      const pos = parseInt(splited[splited.length - 1]);

      const line = value.slice(0, pos).split('\n').length;
      const result = msg.slice(0, msg.indexOf('position')) + 'line ' + line;

      return [[key, ' : 올바른 Array 형식이 아닙니다 ' + result]];
    }
    
    setValue(json_data);
    return [];
  }

  static makeForeignView (datum_key, user_data_key, getName=(item)=>item.name, validate=()=>true) {
    return {
      type: 'view',
      view: (datum, user_data) => {
        if (user_data[user_data_key] === undefined)
          return [];
        
        return user_data[user_data_key].map((item)=>({
          present: `${item.id}: ${getName(item)}`,
          value  : item.id,
        }));
      },
      present: (datum, user_data) => {
        if (user_data[user_data_key] === undefined)
          return '';
        
        const item = user_data[user_data_key].filter((item)=>item.id===datum[datum_key])[0];
        if (item === undefined)
          return '';
        
        return `${item.id}: ${getName(item)}`;
      },
      onChange: (datum, value, user_data) => {
        datum[datum_key] = value;
      },
      validate,
    };
  }



  constructor (format, user_data = {}, lang_dic = {}) {
    if (format === undefined) {
      throw 'error: need format';
    }
    this.format_    = this._reviseFormat(format);
    this.user_data_ = user_data;
    this.lang_dic_  = lang_dic;
  }
  
  getUserdata () {
    return this.user_data_;
  }

  setUserdata (user_data) {
    this.user_data_ = user_data;
  }

  makeFields () {
    const fields = [];
    for (const key in this.format_) {
      const value = this.format_[key];
      if (this._checkIsHidden(value.is_hidden) === true || this._checkIsHidden(value.is_hidden_in_list) === true)
        continue;
      
      fields.push(this.withLangDic(key));
    }

    return fields;
  }

  makeTableRows (data) {
    if (data === undefined)
      return undefined;
    if (data.length <= 0)
      return null;

    const result = [];
    for (const d of data) {
      const row = [];
      for (const key in this.format_) {
        const fmt = this.format_[key];
        if (this._checkIsHidden(fmt.is_hidden) === true || this._checkIsHidden(fmt.is_hidden_in_list) === true)
          continue;
        
        const is_image = this._isImage(d, fmt);
        let value = this._presentValue(d, key);
        if (is_image === true) {
          value = (<img src={value} style={{width: '5rem', height: '5rem'}} />);
        }else if (value === undefined || value === null)
          value = '';
        else if (typeof value === 'string')
          value = value;
        else if (value['$$typeof'] === Symbol.for('react.element'))
          value = value;
        else if (typeof value === 'object' || typeof value === 'objectArray') {
          try {
            value = JSON.stringify(value);
            if (value.length > MAX_LEN_OF_OBJ)
              value = value.slice(0, MAX_LEN_OF_OBJ) + '...';
          }catch (e) {
            ;
          }
        }else
          value = value.toString();
        row.push(value);
      }

      result.push(row);
    }

    return result;
  }

  makeKeyJsxMapForEdit (datum, onAnyChange=()=>{}) {
    const result = {};
    for (const key in this.format_) {
      const fmt = this.format_[key];
      if (this._checkIsHidden(fmt.is_hidden) === true)
        continue;
      
      result[key] = this._makeJsxForEdit(datum, key, datum[key], fmt, onAnyChange, (v)=>datum[key]=v);
    }
    return result;
  }

  validateAll (datum, user_data) {
    if (user_data === undefined)
      user_data = this.user_data_;

    let err_msg = [];  // [[key, err_msg], ...]
    for (const key in this.format_) {
      const fmt = this.format_[key];
      err_msg = err_msg.concat(this.validate(datum, user_data, key, datum[key], fmt, (v)=>datum[key]=v));
    }
    
    return err_msg;
  }

  validate (datum, user_data, key, value, fmt, setValue = (v)=>{}) {
    if (fmt.validate === undefined)
      return [];
    
    return fmt.validate(datum, user_data, key, value, setValue);
  }

  makeDefaultDatum () {
    const result = {};
    for (const key in this.format_) {
      const fmt = this.format_[key];
      result[key] = this._getDefaultValue(fmt);
    }

    return result;
  }

  withLangDic (key) {
    const res = this.lang_dic_[key];
    if (res === undefined)
      return key;
    
    if (this._isFunction(res))
      return res(key);

    return res;
  }
  
  _isFunction (functionToCheck) {
    return (functionToCheck && {}.toString.call(functionToCheck) === '[object Function]')? true: false;
  }

  _getDefaultValue (fmt) {
    if (fmt.default !== undefined) {
      if (this._isFunction(fmt.default) === true)
        return fmt.default(this.user_data_);
      return fmt.default;
    }

    if (Array.isArray(fmt) === true && fmt.length > 0)
      return fmt[0].value;
    
    switch (fmt.type) {
      case 'string': return '';
      case 'int': return 0;
      case 'view':
        if (Array.isArray(fmt.view) === true && fmt.view.length > 0)
          return fmt.view[0].value;
        else
          return fmt.view.value;
      case 'date':
        return string_util.makeYmd(new Date());
      default:
        if (fmt.type.slice(0, 7) === 'commaed')
          return 0;
    }
    
    
    return null;
  }

  _makeJsxForEdit (datum, key, value, fmt, onAnyChange, setValue=(v)=>{}, onChange=(datum, value, user_data)=>{}) {
    if (value === null || value === undefined)
      value = '';
    
    if (typeof value === 'object')
      value = JSON.stringify(value);

    if (fmt.is_readonly !== true)
      fmt.is_readonly = false;

    if (Array.isArray(fmt) === true) {
      if (fmt.is_readonly === true)
        return (<input type="text" value={value} readOnly />);

      const present_value_map = {};
      const value_present_map = {};
      fmt.forEach((f) => {
        const p = this._present(datum, f.present);
        present_value_map[p] = f.value;
        value_present_map[f.value] = p;
      });
      return (
        <SearchableDropdown
          contentHeight="10rem"
          data={{ selected_item: value_present_map[value], items: fmt.map((f)=>this._present(datum, f.present)), }}
          onChange={(i)=>{
            setValue(present_value_map[i]);
            onChange(datum, present_value_map[i], this.user_data_);
            onAnyChange();
          }}
        />
      );
    }

    switch (fmt.type) {
      case 'multi_view':
        const result = [];
        for (const vi in fmt.views) {
          const vf = fmt.views[vi];
          const view = (typeof vf.view === 'function')? vf.view(datum, this.user_data_): vf.view;
          result.push((
            <div key={vi} className="mr-3">
              {
                this._makeJsxForEdit(
                  datum,
                  key,
                  this._present(datum, vf.present),
                  view,
                  onAnyChange,
                  (v)=>{},
                  vf.onChange,
                )
              }
            </div>
          ));
        }
        return (
          <div className="d-flex">
            {result}
          </div>
        );
      case 'view':
        const view = (typeof fmt.view === 'function')? fmt.view(datum, this.user_data_): fmt.view;
        // return this._makeJsxForEdit(datum, key, this._present(datum, fmt.present), view, onAnyChange, (v)=>{}, fmt.onChange);
        return this._makeJsxForEdit(datum, key, value, view, onAnyChange, (v)=>{}, fmt.onChange);
      case 'textArea':
        return (
          <textarea 
            className="form-control"
            value={value}
            rows="10"
            readOnly={fmt.is_readonly}
            onChange={(e)=>{
              setValue(e.target.value);
              onChange(datum, e.target.value, this.user_data_);
              onAnyChange();
            }}
          />
        );
      case 'object':
      case 'objectArray':
        value = (typeof value === 'string')? value: JSON.stringify(value, null, 2);
        return (
          <textarea 
            className="form-control"
            value={value}
            rows="10"
            readOnly={fmt.is_readonly}
            onChange={(e)=>{
              setValue(e.target.value);
              onChange(datum, e.target.value, this.user_data_);
              onAnyChange();
            }}
          />
        );
      case 'uuid':
        return (<UuidInput value={value} onChange={(v) => {
          setValue(v);
          onChange(datum, v, this.user_data_);
          onAnyChange();
        }}/>);
      case 'password':
        return (<input
          type="password"
          className="form-control"
          value={value}
          readOnly={fmt.is_readonly}
          onChange={(e)=>{
            setValue(e.target.value);
            onChange(datum, e.target.value, this.user_data_);
            onAnyChange();
          }}
        />);
      case 'date':
        return (
          <CustomDatePicker
            className="w-100"
            dateFormat="yyyy-MM-dd"
            selected={value===''? new Date(): new Date(value)}
            readOnly={fmt.is_readonly}
            onChange={(dt)=>{
              const v = string_util.makeYmd(dt);
              setValue(v);
              onChange(datum, v, this.user_data_);
              onAnyChange();
            }}
          />
        );
      case 'int':
        return (
          <CommaedNumberInput
            value={value}
            underPointCount={0}
            readOnly={fmt.is_readonly}
            onChange={(value)=>{
              setValue(value);
              onChange(datum, value, this.user_data_);
              onAnyChange();
            }}
          />
        );
      case 'text':
        return (
          <textarea 
            className="form-control"
            value={value}
            rows="10"
            readOnly={fmt.is_readonly}
            onChange={(e)=>{
              setValue(e.target.value);
              onChange(datum, e.target.value, this.user_data_);
              onAnyChange();
            }}
          />
        );
      case 'image':
        return (
          <CustomImage
            field={key}
            src={value}
            onChange={(src) => {
              setValue(src);
              onChange(datum, src, this.user_data_);
              onAnyChange();
            }}
          />
        );
      case 'presented-string':
        return (
          <input
            type="text"
            className="form-control"
            value={this._present(datum, fmt.present)}
            readOnly={true}
          />
        );
      default:
        if (fmt.type.slice(0, 7) === 'commaed') {
          const [t, uc] = fmt.type.split('.');
          // FIXME make CommaedNumber component
          return (
            <CommaedNumberInput
              value={value}
              underPointCount={uc}
              readOnly={fmt.is_readonly}
              onChange={(value)=>{
                setValue(value);
                onChange(datum, value, this.user_data_);
                onAnyChange();
              }}
            />
          );
        }

        return (
          <input
            type="text"
            className="form-control"
            value={value}
            readOnly={fmt.is_readonly}
            onChange={(e)=>{
              setValue(e.target.value);
              onChange(datum, e.target.value, this.user_data_);
              onAnyChange();
            }}
          />
        );
    }
  }

  _present (datum, pres) {
    if (typeof pres === 'object')
      return JSON.stringify(pres);
    else if (this._isFunction(pres) === false)
      return pres;

    return pres(datum, this.user_data_);
  }

  _presentValue (datum, key) {
    const fmt = this.format_[key];

    if (fmt.present === undefined && Array.isArray(fmt) === false) {
      if (fmt.type.slice(0, 7) === 'commaed') {
        const [t, c] = fmt.type.split('.');
        fmt.present = (d, user_data) => string_util.makeCommaedString(d[key], c);
      }
    }

    if (fmt.present === undefined)
      return datum[key];
    
    return this._present(datum, fmt.present);
  }

  _checkIsHidden(is_hidden) {
    if (this._isFunction(is_hidden) === false)
      return is_hidden;

    return is_hidden(this.user_data_);
  }

  _isImage (datum, fmt) {
    if (fmt.type === 'image')
      return true;
    
    if (fmt.type !== 'view')
      return false;
    
    const view = (typeof fmt.view === 'function')? fmt.view(datum, this.user_data_): fmt.view;
    return view.type === 'image';
  }

  _reviseFormat (format) {
    const result = {};

    for (const key in format) {
      const value = format[key];

      const value_type = (Array.isArray(value) === true)? 'array': typeof value;
      switch (value_type) {
        case 'string':
          result[key] = this._reviseFormat_object(key, { type: value, });
          break;
        case 'array':
          result[key] = this._reviseFormat_array(key, value);
          break;
        case 'object':
          result[key] = this._reviseFormat_object(key, value);
          break;
        default:
          throw 'error: value must be one of [string, array, object]';
      }
    }

    return result;
  }

  _reviseFormat_object (key, value) {
    if (value.type === undefined)
      throw 'error: need "type" on ' + key;
    
    switch (value.type) {
      case 'object':
        if (value.validate === undefined)
          value.validate = AdminTemplateEngine.validateJson;
        break;
      case 'objectArray':
        if (value.validate === undefined)
          value.validate = AdminTemplateEngine.validateJsonArray;
        break;
      case 'email':
        if (value.validate === undefined)
          value.validate = AdminTemplateEngine.validateEmail;
    }

    return value;
  }

  _reviseFormat_array (key, arr) {
    const result = [];
    for (const v of arr) {
      const value_type = (Array.isArray(v) === true)? 'array': typeof v;
      if (value_type === 'object') {
        if (v.present === undefined)
          throw 'error: need "present" on ' + key;
        if (v.value === undefined)
          throw 'error: need "value" on ' + key;
        result.push(v);
        continue;
      }

      result.push({
        present: v.toString(),
        value: v,
      });
    }
    return result;
  }
}

export default AdminTemplateEngine;