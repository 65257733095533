import React, { Component } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { toast } from 'react-toastify';

import CommaedNumberInput from '../../snippets/CommaedNumberInput';
import SearchableDropdown from '../../snippets/SearchableDropdown';
import server_api         from '../../util/server_api';
import string_util        from '../../util/string_util';
import common from '../../util/common';


const SUPERUSER_EOS_ACCOUNT = 'himsuper';

class SuperuserTransfer extends Component {
	constructor(props) {
    super(props);

    this.initial_state = {
      is_loading: false,
      
      exchangers: [],
      exchanger_data: {
        selected_item: '',
        items: undefined,
      },
      user_login_id: '',
      eos_account: '',
      receiver_type: 'exchanger',
      receiver_type_list: ['exchanger', 'user', 'account'],
      token_symbol_data: {
        selected_item: '',
        items: undefined,
      },
      token_amount: 0,
      active_key: '',
      memo: '',
    };

    this.state = Object.assign({}, this.initial_state);
    this._fetchDataAndRefresh();
	}
	
	render () {
    common.goBackIfUnavailableRole(['SUPERUSER']);

    const receivertype_kor_map = {
      exchanger: '환전소',
      user: '유저',
      account: '지갑주소',
    };

		return (
      
      <LoadingOverlay
        active={this.state.is_loading}
        spinner
        text='Loading ...'
      >
        <div className="m-0 row justify-content-center" style={{minHeight: '60rem'}}>
          <div className="p-3 col-12 col-md-9 col-lg-6">
            <div className="my-5"></div>

            {/* radio buttons */}
            <div className="my-3 d-flex align-items-center">
              <div className="mr-5 text-right" style={{width: '8rem'}}>받는이 타입</div>
              {
                this.state.receiver_type_list.map((receiver_type, idx) => {
                  return (
                    <div key={idx} className="mr-4 form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="receiver_type"
                        id={`check_${receiver_type}`}
                        value={receiver_type}
                        checked={this.state.receiver_type === receiver_type}
                        onChange={()=>this.setState({receiver_type,})}
                      />
                      <label className="form-check-label" htmlFor={`check_${receiver_type}`}>{receivertype_kor_map[receiver_type]}</label>
                    </div>
                  );
                })
              }
            </div>

            {/* receiver as exchanger */}
            <div
              className={'my-3 align-items-center ' + ((this.state.receiver_type!=='exchanger')? 'd-none':'d-flex')}
            >
              <div className="mr-5 text-right" style={{width: '8rem'}}>환전소</div>
              <div className="flex-grow-1">
                <SearchableDropdown
                  contentHeight="10rem"
                  data={this.state.exchanger_data}
                />
              </div>
            </div>

            {/* receiver as user */}
            <div
              className={'my-3 align-items-center ' + ((this.state.receiver_type!=='user')? 'd-none':'d-flex')}
            >
              <div className="mr-5 text-right" style={{width: '8rem'}}>유저 아이디</div>
              <div className="flex-grow-1">
                <input
                  type="text"
                  className="form-control"
                  value={this.state.user_login_id}
                  onChange={(e)=>this.setState({user_login_id: e.target.value})}
                />
              </div>
            </div>

            {/* receiver as account */}
            <div
              className={'my-3 align-items-center ' + ((this.state.receiver_type!=='account')? 'd-none':'d-flex')}
            >
              <div className="mr-5 text-right" style={{width: '8rem'}}>지갑주소</div>
              <div className="flex-grow-1">
                <input
                  type="text"
                  className="form-control"
                  value={this.state.eos_account}
                  onChange={(e)=>this.setState({eos_account: e.target.value})}
                />
              </div>
            </div>

            {/* token symbole */}
            <div
              className={'my-3 d-flex align-items-center'}
            >
              <div className="mr-5 text-right" style={{width: '8rem'}}>토큰</div>
              <div className="flex-grow-1">
                <SearchableDropdown
                  contentHeight="10rem"
                  data={this.state.token_symbol_data}
                  present={(item) => {
                    const [symbol, amount_str_0, amount_str_1] = item.split(' ');
                    return (
                      <div className="d-flex justify-content-between">
                        <div>{symbol}</div>
                        <div>{amount_str_0} {amount_str_1}</div>
                      </div>
                    );
                  }}
                />
              </div>
            </div>

            {/* token amount */}
            <div
              className={'my-3 d-flex align-items-center'}
            >
              <div className="mr-5 text-right" style={{width: '8rem'}}>보낼 양</div>
              <div className="flex-grow-1">
                <CommaedNumberInput
                  value={this.state.token_amount}
                  underPointCount={4}
                  onChange={(token_amount)=>{
                    this.setState({token_amount});
                  }}
                />
              </div>
            </div>

            {/* active key */}
            <div
              className={'my-3 d-flex align-items-center'}
            >
              <div className="mr-5 text-right" style={{width: '8rem'}}>active key</div>
              <div className="flex-grow-1">
                <input
                  type="password"
                  className="form-control"
                  value={this.state.active_key}
                  onChange={(e)=>{
                    this.setState({active_key: e.target.value});
                  }}
                />
              </div>
            </div>

            {/* memo */}
            <div
              className={'my-3 d-flex align-items-center'}
            >
              <div className="mr-5 text-right" style={{width: '8rem'}}>메모</div>
              <div className="flex-grow-1">
                <input
                  type="text"
                  className="form-control"
                  value={this.state.memo}
                  onChange={(e)=>{
                    this.setState({memo: e.target.value});
                  }}
                />
              </div>
            </div>

            {/* send button */}
            <div className="my-3 d-flex justify-content-end">
              <button className="px-5 btn btn-dark" onClick={()=>this._onClickSend()}>Send</button>
            </div>

          </div>
        </div>
      </LoadingOverlay>
    );
  }
  
  async _fetchDataAndRefresh () {
    this._refreshExchangers();

    this._refreshTokens();
  }

  async _refreshExchangers () {
    const exchangers = (await server_api.fetchAllExchangers()).items;
    this.setState({
      exchangers,
      exchanger_data: {
        selected_item: '',
        items: exchangers.map((exc)=>`${exc.id}: ${exc.login_id}`),
      },
    });
  }

  async _refreshTokens () {
    const balances = (await server_api.fetchEosAccountBalance(SUPERUSER_EOS_ACCOUNT)).items;
    const items = [];
    balances.forEach((b) => {
      const [amount, symbol] = b.split(' ');
      if (symbol === 'MAJOR')
        items.unshift(`${symbol} ${string_util.makeCommaedString(amount, 4).replace('.', ' .')}`);
      else
        items.push(`${symbol} ${string_util.makeCommaedString(amount, 4).replace('.', ' .')}`);
    });
    this.setState({
      token_symbol_data: {
        selected_item: '',
        items,
      },
    });
  }

  async _onClickSend () {
    // validation
    if (this.state.receiver_type_list.indexOf(this.state.receiver_type) < 0)
      return toast.error(<span>올바른 <b>받는이 타입</b>을 선택해 주세요</span>);
    if (this.state.receiver_type === 'exchanger' && this.state.exchanger_data.selected_item === '')
      return toast.error(<span>올바른 <b>환전소</b>를 선택해 주세요</span>);
    if (this.state.receiver_type === 'user' && this.state.user_login_id.trim() === '')
      return toast.error(<span><b>유저 아이디</b>를 입력해 주세요</span>);
    if (this.state.receiver_type === 'account' && this.state.eos_account.trim() === '')
      return toast.error(<span><b>지갑주소</b>를 입력해 주세요</span>);
    if (this.state.token_symbol_data.selected_item === '')
      return toast.error(<span>올바른 <b>토큰</b>을 선택해 주세요</span>);
    if (parseFloat(this.state.token_amount) <= 0.0)
      return toast.error(<span><b>보낼 양</b>은 0보다 커야 합니다</span>);
    if (this.state.active_key === '')
      return toast.error(<span><b>active key</b>를 입력해 주세요</span>);


    // send
    this.setState({is_loading: true});
    
    try {
      // fetch to_account
      let to_account = null;
      switch (this.state.receiver_type) {
        case 'exchanger':
          const id = parseInt(this.state.exchanger_data.selected_item.split(':')[0]);
          const exchanger = (await server_api.fetchExchangers({ where: { id, }, })).items[0];
          if (!exchanger) {
            toast.error(<span>no exchanger for <b>{this.state.exchanger_data.selected_item}</b></span>);
            return this.setState({is_loading: false});
          }
          to_account = exchanger.eos_account;
          break;
        case 'user':
          const user = (await server_api.fetchUsers({ where: { login_id: this.state.user_login_id, }, })).items[0];
          if (!user) {
            toast.error(<span>no user for <b>{this.state.user_login_id}</b></span>);
            return this.setState({is_loading: false});
          }
          to_account = user.eos_account;
          break;
        case 'account':
          to_account = this.state.eos_account;
          break;
        default:
          toast.error(<span>select valid <b>receiver type</b></span>);
          return this.setState({is_loading: false});
      }

      // transfer
      const token_symbol = this.state.token_symbol_data.selected_item.split(' ')[0];
      const token_amount_str = parseFloat(this.state.token_amount).toFixed(
        (['HIC', 'HIM'].indexOf(token_symbol) >= 0)? 2:4
      );
      await server_api.transferToken(
        SUPERUSER_EOS_ACCOUNT,
        this.state.active_key,
        to_account,
        token_amount_str,
        token_symbol,
        this.state.memo,
      );

      toast.success(
        <div>
          <div>sent <b>{token_amount_str} {token_symbol}</b></div>
          <div>to <b>{to_account}</b></div>
        </div>
      )

    }catch (e) {
      console.log(JSON.stringify(e, null, 2));
      if (e.result && e.result.message) {
        if (e.result.message.startsWith('Error: Invalid checksum,'))
          toast.error(<span>input valid <b>active key</b></span>);
        else {
          toast.error(
            <div>
              <div>{e.result.message}</div>
              {
                (!e.result.details)? null:
                e.result.details.map((d, di) => <div key={di}>{d}</div>)
              }
            </div>
          );
        }
      }else if (e.message)
        toast.error(e.message);
      else
        toast.error('' + e);
      return this.setState({is_loading: false});
    }

    this.setState(
      Object.assign({}, this.initial_state, {is_loading: false})
    );
    this._fetchDataAndRefresh();
  }
  
}


export default SuperuserTransfer;