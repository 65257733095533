import AdminTemplateEngine from "../../util/admin_template_engine";
import string_util from '../../util/string_util';

export default {
  data_format: {
    id          : { type: 'int'     , is_readonly: true, },
    title       : { type: 'string'  , validate: AdminTemplateEngine.validateNotEmpty, },
    writer_role : { type: 'string'  , is_hidden: true },
    writer_id   : { type: 'int'     , is_hidden: true, },
    writer: {
      type: 'view',
      view: (datum, user_data) => {
        return { type: 'string', is_readonly: true, };
      },
      present : (datum, user_data) => {
        return `${datum.writer_role}: ${datum.writer_id}`;
      },
      onChange: (datum, value, user_data)=>{},
    },
    updater_role: { type: 'string'  , is_hidden: true },
    updater_id  : { type: 'int'     , is_hidden: true, },
    updater: {
      type: 'view',
      view: (datum, user_data) => {
        return { type: 'string', is_readonly: true, };
      },
      present : (datum, user_data) => {
        return `${datum.updater_role}: ${datum.updater_id}`;
      },
      onChange: (datum, value, user_data)=>{},
    },
    created : {
      type: 'view',
      view: (datum, user_data) => {
        return { type: 'string', is_readonly: true, };
      },
      present : (datum, user_data) => {
        if (!datum.createdAt)
          return '';
        const dt = new Date(datum.createdAt);
        return string_util.makeYmdHms(dt);
      },
      onChange: (datum, value, user_data)=>{},
      is_readonly: true,
    },
    updated : {
      type: 'view',
      view: (datum, user_data) => {
        return { type: 'string', is_readonly: true, };
      },
      present : (datum, user_data) => {
        if (!datum.updatedAt)
          return '';
        const dt = new Date(datum.updatedAt);
        return string_util.makeYmdHms(dt);
      },
      onChange: (datum, value, user_data)=>{},
      is_readonly: true,
    },
  },

  lang_dic: {
    title: '제목',
    writer: '작성자',
    updater: '수정자',
    created: '생성일',
    updated: '수정일',
  },
}
