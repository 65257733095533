import React, { Component } from 'react';
import uuid from 'uuid/v4';
import $ from 'jquery';

class YesNoModal extends Component {
  constructor (props) {
    super(props);

    this.state = {};

    this.is_over_ = false;
    this.is_yes_  = false;
  }

  componentDidMount () {
    $('#' + this.props.id).on('hidden.bs.modal', (e) => {
      this.is_over_ = false;
      if (this.is_yes_ === true)
        this.props.onYesHidden();
    });
  }

	render () {
    const err_div = (this.props.errMessages.length <= 0)? null:
      (
        <div className="w-100 alert alert-danger">
          {
            this.props.errMessages.map((em, idx)=>{
              return (<div>{em}</div>);
            })
          }
        </div>
      );
		return (
			<div className="modal fade" id={this.props.id} tabIndex="-1" role="dialog" aria-labelledby="ModalLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
					<div className="modal-content">
            <div className="modal-body" id="modalContent">
              {this.props.message.split('\n').map((msg, idx)=><div key={idx}>{msg}</div>)}
              {this.props.body}
            </div>
            {err_div}
						<div className="modal-footer">
              <button
                type="button"
                className={'btn btn-secondary' + ((this.props.needNo)? '':' d-none')}
                onClick={()=>{
                  if (this.is_over_ === true)
                    return;
                  
                  this.is_over_ = true;
                  this.is_yes_  = false;
                  $('#' + this.props.id).modal('hide');
                }}
              >
                {this.props.noStr}
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={()=>{
                  if (this.is_over_ === true)
                    return;
                  
                  this.is_over_ = true;
                  this.is_yes_  = true;
                  this._onYes();
                }}
              >
                {this.props.yesStr}
              </button>
						</div>
					</div>
				</div>
			</div>
		);
  }
  
  async _onYes () {
    this.is_yes_ = await this.props.onYes();
    $('#' + this.props.id).modal('hide');
  }
}

YesNoModal.defaultProps = {
  id: uuid(),
  message: '메세지 부분 입니다.',
  errMessages: [],
  onYes: () => true,
  onYesHidden: () => {},
  body: null,
  needNo: true,
  yesStr: 'Yes',
  noStr: 'No',
};

export default YesNoModal;