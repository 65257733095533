
import React, { Component } from 'react';



class LoadingText extends Component {
  render () {
    return (    
      <div className="d-flex" style={{fontSize:'1rem'}}>
        <div className="mr-2">
          <div className="spinner-border text-secondary spinner-border-sm" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
        <div>
          {this.props.text}
        </div>
      </div>

    );
  }
}

LoadingText.defaultProps = {
  text: 'Loading...',
};

export default LoadingText;