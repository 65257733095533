import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import LoadingOverlay from 'react-loading-overlay';
import { toast } from 'react-toastify';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';

import SearchableDropdown from '../../snippets/SearchableDropdown';
import date_util          from '../../util/date_util';
import server_api         from '../../util/server_api';
import string_util        from '../../util/string_util';
import { domainToUnicode } from 'url';


const COLORS = [
  '#3f495c',
  '#575378',
  '#80598a',
  '#ae5a8d',
  '#d95c80',
  '#f96866',
  '#ff8242',
  '#ffa600',
];

const statistics_kor_dic = {
  'tokens'  : '코인 선택',
  'data'    : '데이터',
  'unit'    : '날짜 단위',
  'duration': '기간',
  'apply'   : '적용',
  'data/'                       : '',
  'data/money_to_token_ratio'   : '충전 비율',
  'data/token_of_money_to_token': '충전 코인량',
  'data/money_of_token_to_money': '환전 화폐량',
  'data/token_of_token_to_money': '환전 코인량',
  'unit/'         : '',
  'unit/per_day'  : '일별',
  'unit/per_week' : '주별',
  'unit/per_month': '월별',
  'unit/per_year' : '연별',
};

class Statistics extends Component {
	constructor(props) {
    super(props);

    this.state = {
      is_loading: true,

      tokens: [],
      token_symbols: [],
      selected_tokens: [],
      data_item_keys: [],
      data_dropdown_data: {
        selected_item: '',
        items: [
          'money_to_token_ratio',
          'token_of_money_to_token',
          'money_of_token_to_money',
          'token_of_token_to_money',
        ],
      },
      unit_dropdown_data: {
        selected_item: '',
        items: ['per_day', 'per_week', 'per_month', 'per_year'],
      },
      duration_start: '',
      duration_end: '',

      data: [],
    };
    
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this._fetchDataAndRefresh();
  }
  
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
  }
	
	render () {
    const unselected_tokens = this.state.token_symbols.filter((token)=>this.state.selected_tokens.indexOf(token) < 0);

		return (
      <LoadingOverlay
        active={this.state.is_loading}
        spinner
        text='Loading ...'
      >
        <div style={{minHeight: '60rem'}}>
          {/* top part */}
          <div className="w-100 p-2" style={{backgroundColor:'#e6e6e6'}}>

            {/* tokens */}
            <div className="row m-0 my-2 align-items-center">
              <div className="mr-3 mb-2">{statistics_kor_dic['tokens']}</div>

              {/* selected tokens */}
              {
                this.state.selected_tokens.map((token, idx) => {
                  return  (
                    <div key={idx} className="mr-3 mb-2" style={{width: '10rem'}}>
                      <SearchableDropdown
                        contentHeight="10rem"
                        data={{selected_item: token, items: ['$delete', token].concat(unselected_tokens)}}
                        onChange={(item) => {
                          if (item === '$delete') {
                            this.state.selected_tokens.splice(idx, 1);
                            return this.setState({
                              selected_tokens: this.state.selected_tokens,
                            });
                          }

                          this.state.selected_tokens[idx] = item;
                          this.setState({
                            selected_tokens: this.state.selected_tokens,
                          });
                        }}
                        present={(item) => {
                          if (item[0] !== '$')
                            return item;
                          return <div className="text-danger">{item.substr(1)}</div>
                        }}
                      />
                      </div>
                  );
                })
              }

              {/* for new selected token */}
              <div className="mr-3 mb-2" style={{width: '10rem'}}>
                <SearchableDropdown
                  contentHeight="10rem"
                  data={{selected_item: '', items: unselected_tokens}}
                  onChange={(item)=>{
                    this.setState({
                      selected_tokens: this.state.selected_tokens.concat([item]),
                    });
                  }}
                />
              </div>
            </div>

            <div className="row m-0 align-items-center">
              {/* data */}
              <div className="mb-2">
                <div className="row m-0 align-items-center">
                  <div className="mr-3">{statistics_kor_dic['data']}</div>
                  <div className="mr-4" style={{width: '15rem'}}>
                    <SearchableDropdown
                      contentHeight="10rem"
                      data={this.state.data_dropdown_data}
                      onChange={(item)=>{
                      }}
                      present={(item)=>statistics_kor_dic[`data/${item}`]}
                    />
                  </div>
                </div>
              </div>
              
              {/* unit */}
              <div className="mb-2">
                <div className="row m-0 align-items-center">
                  <div className="mr-3">{statistics_kor_dic['unit']}</div>
                  <div className="mr-4" style={{width: '10rem'}}>
                    <SearchableDropdown
                      contentHeight="10rem"
                      data={this.state.unit_dropdown_data}
                      onChange={(item)=>{
                      }}
                      present={(item)=>statistics_kor_dic[`unit/${item}`]}
                    />
                  </div>
                </div>
              </div>

              {/* duration */}
              <div className="row m-0 mb-2 align-items-center">
                <div className="mr-3">{statistics_kor_dic['duration']}</div>
                <div className="">
                  <DatePicker
                    dateFormat="yyyy-MM-dd"
                    selectsStart
                    selected={this.state.duration_start}
                    startDate={this.state.duration_start}
                    endDate={this.state.duration_end}
                    onChange={(d)=>this.setState({duration_start: d})}
                  />
                </div>
                <div className="mx-2">~</div>
                <div className="mr-4">
                  <DatePicker
                    dateFormat="yyyy-MM-dd"
                    selectsEnd
                    selected={this.state.duration_end}
                    startDate={this.state.duration_start}
                    endDate={this.state.duration_end}
                    onChange={(d)=>this.setState({duration_end: d})}
                  />
                </div>

                {/* apply button for md<= */}
                <button
                  className="d-none d-md-block px-3 btn btn-sm btn-primary"
                  onClick={()=>this._onApply()}
                >
                  {statistics_kor_dic['apply']}
                </button>
              </div>

            </div>
            
            {/* apply button for md> */}
            <div>
              <div className="d-block d-md-none flex-grow-1">
                <div className="d-flex justify-content-end">
                  <button
                    className="mr-3 mb-2 px-3 btn btn-primary"
                    onClick={()=>this._onApply()}
                  >
                    {statistics_kor_dic['apply']}
                  </button>
                </div>
              </div>
            </div>

          </div>

          {/* graph part */}
          <LineChart
            width ={this.state.windowWidth  * 0.9 }
            height={this.state.windowHeight * 0.75}
            data={this.state.data}
            margin={{
              top: 5, right: 30, left: 20, bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            {
              this.state.data_item_keys.map((token, idx) => {
                return (
                  <Line
                    key={idx}
                    type="monotone"
                    dataKey={token}
                    stroke={COLORS[idx%COLORS.length]}
                    activeDot={{ r: 8 }}
                  />
                );
              })
            }
          </LineChart>

        </div>
      </LoadingOverlay>
    );
  }
  
  async _fetchDataAndRefresh () {

    this.setState({
      is_loading: true,
    });

    const tokens = (await server_api.fetchTokens()).items;
    const token_symbols = tokens.map((t)=>t.token_symbol);

    this.setState({
      is_loading: false,

      tokens,
      token_symbols,
    });
  }

  async _onApply () {
    if (this.state.selected_tokens.length <= 0)
      return toast.error(<span><b>코인</b>을 선택하세요</span>);

    if (this.state.data_dropdown_data.selected_item === '')
      return toast.error(<span><b>데이터</b>를 선택하세요</span>);

    if (this.state.unit_dropdown_data.selected_item === '')
      return toast.error(<span><b>날짜 단위</b>를 선택하세요</span>);
      
    const is_duration_valid = this.state.duration_start !== '' &&
        this.state.duration_end !== '' &&
        this.state.duration_start <= this.state.duration_end;
    if (is_duration_valid === false)
      return toast.error(<span>올바른 <b>기간</b>을 선택하세요</span>);

    this.setState({
      is_loading: true,
    });

    switch (this.state.data_dropdown_data.selected_item) {
      case 'money_to_token_ratio':
        await this._makeGraph_mtt_ratio(
          this.state.selected_tokens,
          this.state.unit_dropdown_data.selected_item,
          this.state.duration_start,
          this.state.duration_end,
        );
        break;
      case 'token_of_money_to_token':
        await this._makeGraph_mtt_token_amount(
          this.state.selected_tokens,
          this.state.unit_dropdown_data.selected_item,
          this.state.duration_start,
          this.state.duration_end,
        );
        break;
      case 'money_of_token_to_money':
        await this._makeGraph_ttm_money_amount(
          this.state.selected_tokens,
          this.state.unit_dropdown_data.selected_item,
          this.state.duration_start,
          this.state.duration_end,
        );
        break;
      case 'token_of_token_to_money':
        await this._makeGraph_ttt_token_amount(
          this.state.selected_tokens,
          this.state.unit_dropdown_data.selected_item,
          this.state.duration_start,
          this.state.duration_end,
        );
        break;
      default:
        toast.error(<span>unknown data value: <b>{this.state.data_dropdown_data.selected_item}</b></span>);
        return this.setState({is_loading: false});
    }

    this.setState({
      is_loading: false,
    });
  }

  async _makeGraph_mtt_ratio (selected_tokens, unit, duration_start, duration_end) {
    const {start_dt, end_dt} = this._makeStartEndDt(unit, duration_start, duration_end);
    if (!start_dt)
      return;

    // fetch
    const mtt_list = (await server_api.fetchMoneyToTokens({
      where: {
        status: 'OK',
        token_symbol: { '$in': selected_tokens, },
        sealedAt: {
          '$gte': start_dt,
          '$lt' : end_dt,
        },
      },
    })).items;

    const ttm_list = (await server_api.fetchTokenToMoneys({
      where: {
        status: 'OK',
        token_symbol: { '$in': selected_tokens, },
        sealedAt: {
          '$gte': start_dt,
          '$lt' : end_dt,
        },
      },
    })).items;
    
    const ttt_list = (await server_api.fetchTokenToTokens({
      where: {
        status: 'OK',
        from_token_symbol: { '$in': selected_tokens, },
        sealedAt: {
          '$gte': start_dt,
          '$lt' : end_dt,
        },
      },
    })).items;
    

    // make count map
    const ymd_token_mttcnt_map = {};
    const ymd_token_totalcnt_map = {};
    const __incYmcTokenCntMap = (map, ymd, token) => {
      if (!map[ymd])
        map[ymd] = {};
      if (!map[ymd][token])
        map[ymd][token] = 0;
      map[ymd][token] += 1;
    };
    const __ymdMaker = this._ymdMakerMaker(unit);
    mtt_list.forEach((mtt) => {
      const ymd = __ymdMaker(mtt.sealedAt);
      __incYmcTokenCntMap(ymd_token_mttcnt_map  , ymd, mtt.token_symbol);
      __incYmcTokenCntMap(ymd_token_totalcnt_map, ymd, mtt.token_symbol);
    });
    ttm_list.forEach((ttm) => {
      const ymd = __ymdMaker(ttm.sealedAt);
      __incYmcTokenCntMap(ymd_token_totalcnt_map, ymd, ttm.token_symbol);
    });
    ttt_list.forEach((ttt) => {
      const ymd = __ymdMaker(ttt.sealedAt);
      __incYmcTokenCntMap(ymd_token_totalcnt_map, ymd, ttt.from_token_symbol);
    });


    // make data
    const data = [];
    for (let dt=new Date(start_dt), old_ymd=null; dt<end_dt; dt.setDate(dt.getDate()+1)) {
      const ymd = __ymdMaker(dt);
      if (ymd === old_ymd)
        continue;
      old_ymd = ymd;

      const datum = { name: ymd };
      for (const token of selected_tokens) {
        const mtt_count = (ymd_token_mttcnt_map[ymd] && ymd_token_mttcnt_map[ymd][token]) || 0;
        if (mtt_count === 0)
          datum[token] = 0;
        else
          datum[token] = mtt_count/ymd_token_totalcnt_map[ymd][token];
      }
      data.push(datum);
    }


    this.setState({
      data,
      data_item_keys: selected_tokens,
    });
  }

  async _makeGraph_mtt_token_amount (selected_tokens, unit, duration_start, duration_end) {
    const {start_dt, end_dt} = this._makeStartEndDt(unit, duration_start, duration_end);
    if (!start_dt)
      return;

    // fetch
    const mtt_list = (await server_api.fetchMoneyToTokens({
      where: {
        status: 'OK',
        token_symbol: { '$in': selected_tokens, },
        sealedAt: {
          '$gte': start_dt,
          '$lt' : end_dt,
        },
      },
    })).items;
    

    // make amount map
    const ymd_token_mttamt_map = {};
    const __incYmcTokenAmountMap = (map, ymd, token, amount) => {
      if (!map[ymd])
        map[ymd] = {};
      if (!map[ymd][token])
        map[ymd][token] = 0;
      map[ymd][token] += parseFloat(amount);
    };
    const __ymdMaker = this._ymdMakerMaker(unit);
    mtt_list.forEach((mtt) => {
      const ymd = __ymdMaker(mtt.sealedAt);
      __incYmcTokenAmountMap(ymd_token_mttamt_map, ymd, mtt.token_symbol, mtt.token_amount);
    });


    // make data
    const data = [];
    for (let dt=new Date(start_dt), old_ymd=null; dt<end_dt; dt.setDate(dt.getDate()+1)) {
      const ymd = __ymdMaker(dt);
      if (ymd === old_ymd)
        continue;
      old_ymd = ymd;

      const datum = { name: ymd };
      for (const token of selected_tokens) {
        const mtt_amount = (ymd_token_mttamt_map[ymd] && ymd_token_mttamt_map[ymd][token]) || 0;
        if (mtt_amount === 0)
          datum[token] = 0;
        else
          datum[token] = mtt_amount;
      }
      data.push(datum);
    }


    this.setState({
      data,
      data_item_keys: selected_tokens,
    });
  }

  async _makeGraph_ttm_money_amount (selected_tokens, unit, duration_start, duration_end) {
    const {start_dt, end_dt} = this._makeStartEndDt(unit, duration_start, duration_end);
    if (!start_dt)
      return;

    // fetch
    const ttm_list = (await server_api.fetchTokenToMoneys({
      where: {
        status: 'OK',
        token_symbol: { '$in': selected_tokens, },
        sealedAt: {
          '$gte': start_dt,
          '$lt' : end_dt,
        },
      },
    })).items;
    const token_list = (await server_api.fetchTokens({
      where: {
        token_symbol: { '$in': selected_tokens, },
      },
    })).items;


    // make name_token_map
    const token_currency_map = {};
    token_list.forEach((token)=>token_currency_map[token.token_symbol]=token.currency_symbol);
    

    // make amount map
    const ymd_currency_ttmamt_map = {};
    const __incYmcTokenAmountMap = (map, ymd, currency, amount) => {
      if (!map[ymd])
        map[ymd] = {};
      if (!map[ymd][currency])
        map[ymd][currency] = 0;
      map[ymd][currency] += parseFloat(amount);
    };
    const __ymdMaker = this._ymdMakerMaker(unit);
    ttm_list.forEach((ttm) => {
      const ymd = __ymdMaker(ttm.sealedAt);
      __incYmcTokenAmountMap(
        ymd_currency_ttmamt_map,
        ymd,
        token_currency_map[ttm.token_symbol],
        ttm.money_amount,
      );
    });


    // make data
    const selected_currencies = selected_tokens.map((t)=>token_currency_map[t]);
    const data = [];
    for (let dt=new Date(start_dt), old_ymd=null; dt<end_dt; dt.setDate(dt.getDate()+1)) {
      const ymd = __ymdMaker(dt);
      if (ymd === old_ymd)
        continue;
      old_ymd = ymd;

      const datum = { name: ymd };
      for (const currency of selected_currencies) {
        const ttm_amount = (ymd_currency_ttmamt_map[ymd] && ymd_currency_ttmamt_map[ymd][currency]) || 0;
        if (ttm_amount === 0)
          datum[currency] = 0;
        else
          datum[currency] = ttm_amount;
      }
      data.push(datum);
    }


    this.setState({
      data,
      data_item_keys: selected_currencies,
    });
  }

  async _makeGraph_ttt_token_amount (selected_tokens, unit, duration_start, duration_end) {
    const {start_dt, end_dt} = this._makeStartEndDt(unit, duration_start, duration_end);
    if (!start_dt)
      return;

    // fetch
    const ttt_list = (await server_api.fetchTokenToTokens({
      where: {
        status: 'OK',
        to_token_symbol: { '$in': selected_tokens, },
        sealedAt: {
          '$gte': start_dt,
          '$lt' : end_dt,
        },
      },
    })).items;
    

    // make amount map
    const ymd_token_tttamt_map = {};
    const __incYmcTokenAmountMap = (map, ymd, token, amount) => {
      if (!map[ymd])
        map[ymd] = {};
      if (!map[ymd][token])
        map[ymd][token] = 0;
      map[ymd][token] += parseFloat(amount);
    };
    const __ymdMaker = this._ymdMakerMaker(unit);
    ttt_list.forEach((ttt) => {
      const ymd = __ymdMaker(ttt.sealedAt);
      __incYmcTokenAmountMap(ymd_token_tttamt_map, ymd, ttt.to_token_symbol, ttt.to_token_amount);
    });


    // make data
    const data = [];
    for (let dt=new Date(start_dt), old_ymd=null; dt<end_dt; dt.setDate(dt.getDate()+1)) {
      const ymd = __ymdMaker(dt);
      if (ymd === old_ymd)
        continue;
      old_ymd = ymd;

      const datum = { name: ymd };
      for (const token of selected_tokens) {
        const ttt_amount = (ymd_token_tttamt_map[ymd] && ymd_token_tttamt_map[ymd][token]) || 0;
        if (ttt_amount === 0)
          datum[token] = 0;
        else
          datum[token] = ttt_amount;
      }
      data.push(datum);
    }


    this.setState({
      data,
      data_item_keys: selected_tokens,
    });
  }

  
  _ymdMakerMaker (unit) {
    return (
      (unit === 'per_day'  )? (dt)=>string_util.makeYmd(dt):
      (unit === 'per_week' )? (dt)=>date_util.makeYearMonthWeek(dt):
      (unit === 'per_month')? (dt)=>string_util.makeYmd(dt).split('-').slice(0, -1).join('-'):
      (unit === 'per_year' )? (dt)=>string_util.makeYmd(dt).split('-')[0]:
      null
    );
  }

  _makeStartEndDt (unit, duration_start, duration_end) {
    let start_dt = null;
    let end_dt   = null;
    switch (unit) {
      case 'per_day':
        start_dt = new Date(duration_start);
        end_dt   = new Date(duration_end  );
        end_dt.setDate(end_dt.getDate() + 1);
        break;
      case 'per_week':
        start_dt = new Date(duration_start);
        end_dt   = new Date(duration_end  );
        start_dt.setDate(start_dt.getDate() - start_dt.getDay() + 1);
        end_dt  .setDate(end_dt  .getDate() - end_dt  .getDay() + 8);
        break;
      case 'per_month':
        start_dt = date_util.getFirstMonthDate(duration_start);
        end_dt   = date_util.getFirstMonthDate(duration_end  );
        end_dt.setMonth(end_dt.getMonth() + 1);
        break;
      case 'per_year':
        start_dt = date_util.getFirstYearDate(duration_start);
        end_dt   = date_util.getFirstYearDate(duration_end  );
        end_dt.setFullYear(end_dt.getFullYear() + 1);
        break;
      default:
        toast.error(<span>select valid <b>Unit</b></span>);
        return {start_dt: null, end_dt: null};
    }

    return {start_dt, end_dt};
  }


}


export default Statistics;