
import React, { Component } from 'react';



class NoValueText extends Component {
  render () {
    return (
      <span style={{fontSize:'0.9rem', color:'grey'}}>no value</span>
    );
  }
}

export default NoValueText;