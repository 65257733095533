import React, {Component} from 'react';

import SearchIcon from './SearchIcon'

class SearchableDropdown extends Component {
  constructor (props) {
    super(props);

    this.state = {
      filter_text: '',
    };
  }

  render () {
    let item_list = this.props.data.items;
    if (item_list === undefined)
      item_list = [this.props.loadingStr];

    if (this.state.filter_text !== '') {
      const re = new RegExp(this.state.filter_text.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'));
      item_list = item_list.filter((item)=>re.test(item));
    }

    const items_jsx = [];
    for (const ii in item_list) {
      const item = item_list[ii];
      const presented = this.props.present(item);
      let class_name = 'dropdown-item';
      if (presented === true || presented === 'true')
        class_name += ' text-success';
      else if (presented === false || presented === 'false')
        class_name += ' text-danger';

      items_jsx.push((
        <button
          className={class_name}
          type="button"
          key={ii}
          onClick={()=>this._onClickItem(item)}
        >
          {presented}
        </button>
      ));
    }

    
    let btn_class_name = 'w-100 btn ' + this.props.btnClassName;
    if (this.props.data.selected_item === true || this.props.data.selected_item === 'true')
      btn_class_name += ' text-success';
    else if (this.props.data.selected_item === false || this.props.data.selected_item === 'false')
      btn_class_name += ' text-danger';

    return (
      <div className={'dropdown ' + this.props.className} style={this.props.style}>
        <button className={btn_class_name} type="button" id="dropdownItems" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <div className="w-100 d-flex">
            <div className="flex-grow-1">
              {this.props.present(this.props.data.selected_item)}
            </div>
            <div className="dropdown-toggle"></div>
          </div>
        </button>
        <div className="w-100 dropdown-menu" aria-labelledby="dropdownItems">
          <div className="d-flex mx-2 mb-2">
            <SearchIcon className="mr-2" width="1rem" height="1rem" fill="grey" stroke="grey" />
            <input type="text" className="w-100 mr-2 border-0" placeholder="Search.." onKeyUp={(e)=>this._onFilterChange(e)} />
          </div>
          <div style={{overflow:'scroll', height:this.props.contentHeight}}>
            {items_jsx}
          </div>
        </div>
      </div>
    );
  }

  _onFilterChange (evt) {
    this.setState({
      filter_text: evt.target.value.replace(/\\/g, '').trim(),
    });
  }

  _onClickItem (item) {
    if (item === this.props.loadingStr)
      return;

    this.props.data.selected_item = item;
    this.setState({});
    this.props.onChange(item);
  }
}

SearchableDropdown.defaultProps = {
  className: '',
  btnClassName: 'btn-light',
  style: {},
  contentHeight: '30rem',
  data: {
    selected_item: '',
    items: [],
  },
  onChange: (item) => {},
  present: (item) => item,
  loadingStr: 'loading...',
};

export default SearchableDropdown;