import string_util from "../../util/string_util";

const ddic = {
  REQUEST: "환전요청",
  SENDED: "환전완료",
  FAIL: "환전거부",
};
export default {
  data_format: {
    id: { type: "int", is_readonly: true },
    user_id: { type: "int", is_readonly: true },
    memo: { type: "string" },
    token: { type: "string", is_readonly: true },
    targetToken: { type: "string", is_readonly: true },
    amount: { type: "string", is_readonly: true },
    transaction: { type: "string", is_readonly: true },

    state: ["REQUEST", "SENDED", "FAIL"].map((cc) => {
      return {
        present: `${cc}: ${ddic[cc]}`,
        value: cc,
      };
    }),
    updated: {
      type: "view",
      view: (datum, user_data) => {
        return { type: "string", is_readonly: true };
      },
      present: (datum, user_data) => {
        if (!datum.updatedAt) return "";
        const dt = new Date(datum.updatedAt);
        return string_util.makeYmdHms(dt);
      },
      onChange: (datum, value, user_data) => {},
      is_readonly: true,
    },
  },
  lang_dic: {
    user_id: "요청아이디",
    memo: "메모",
    token: "변환전토큰",
    targetToken: "변환후토큰",
    amount: "변환할 수량",
    transaction: "트랜잭션",
    state: "처리상태(REQUEST:요청, SENDED:변환완료, FAIL:거부)",
    updated: "생성일",
  },
};
