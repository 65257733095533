import AdminTemplateEngine from "../../util/admin_template_engine";
import country_codes from "../../util/country_codes";

const role_kor_map = {
  ADMIN: "부본사",
  SUPERUSER: "본사",
};

export default {
  data_format: {
    id: { type: "int", is_readonly: true },
    login_id: {
      type: "string",
      validate: AdminTemplateEngine.validateNotEmpty,
    },
    password: {
      type: "password",
      is_hidden_in_list: true,
      deafult: "password",
    },
    pk: {
      type: "password",
      is_hidden_in_list: true,
    },

    pass: {
      type: "password",
      is_hidden_in_list: true,
    },
    oc_url: {
      type: "string",
    },
    apkey: {
      type: "password",
      is_hidden_in_list: true,
    },
  },

  lang_dic: {
    login_id: "로그인 아이디",
    password: "패스워드",
    pk: "옥텟 프라이빗키",
    pass: "옥텟패스워드",
    apkey: "옥텐API키, 3개월마다 바꿔줘야합니다.",
  },
};
