import React, { Component } from 'react';


class Pagination extends Component {
  render () {
    const show_limit = this.props.showLimit;
    const count = this.props.count;
    const data = this.props.data;

    let first_no = Math.max(Math.round(data.current - (show_limit-1)/2), 1    );
    let last_no  = Math.min(Math.round(data.current + (show_limit-1)/2), count);
    if (first_no === 1)
      last_no = Math.min(first_no + (show_limit-1), count);
    if (last_no === count)
      first_no = Math.max(last_no - (show_limit-1), 1);
    
    const left_side = (first_no === 1)? null:
      [
        (<button key={1} className="btn btn-light" onClick={()=>this._onClick(1)} > {1} </button>),
        (first_no === 2)? null: (<button key="left..." className="btn btn-light">...</button>),
      ];
    const right_side = (last_no === count)? null:
      [
        (last_no === count-1)? null: (<button key="right..." className="btn btn-light">...</button>),
        (<button key={count} className="btn btn-light" onClick={()=>this._onClick(count)} > {count} </button>),
      ];

    const pages_jsx = [];
    for (let page=first_no, i=0; page<=last_no; ++page, ++i) {
      const page_no = page;
      pages_jsx.push(
        <button
          key={i}
          className={'btn ' + ((page === data.current)? 'btn-primary':'btn-light')}
          onClick={()=>this._onClick(page_no)}
        >
          {page}
        </button>
      );
    }

    return (
      <div className={this.props.className}>
        <div className="btn-group" role="group" aria-label="pagination">
          <button className="btn btn-light" onClick={()=>this._onPre()}>{'<'}</button>
          {left_side}
          {pages_jsx}
          {right_side}
          <button className="btn btn-light" onClick={()=>this._onNext()}>{'>'}</button>
        </div>
      </div>
    );
  }

  _onPre () {
    const old = this.props.data.current;
    this.props.data.current = Math.max(this.props.data.current - 1, 1);
    if (this.props.data.current === old)
      return;

    this.setState({});
    this.props.onChange(this.props.data.current);
  }

  _onNext () {
    const old = this.props.data.current;
    this.props.data.current = Math.min(this.props.data.current + 1, this.props.count);
    if (this.props.data.current === old)
      return;

    this.setState({});
    this.props.onChange(this.props.data.current);
  }

  _onClick (page_no) {
    const old = this.props.data.current;
    this.props.data.current = page_no;
    if (this.props.data.current === old)
      return;

    this.setState({});
    this.props.onChange(page_no);
  }
}

Pagination.defaultProps = {
  className: '',
  count: 1,
  showLimit: 7,
  data: {
    current: 1,
  },
  onChange: (page_no) => {},
};

export default Pagination;
