import React from 'react';

import AdminTemplateEngine from "../../../util/admin_template_engine";
import string_util         from "../../../util/string_util";

export default {
  data_format: {
    id      : { type: "int", is_readonly: true, },
    created : {
      type: 'view',
      view: (datum, user_data) => '',
      present : (datum, user_data) => {
        const dt = new Date(datum.createdAt);
        return string_util.makeYmdHms(dt);
      },
      onChange: (datum, value, user_data)=>{},
    },
    user_id : { type: 'int', is_hidden: true, },
    user    : {
      type: 'view',
      view: (datum, user_data) => '',
      present : (datum, user_data) => {
        return `${datum.user.id}: ${datum.user.login_id}`;
      },
      onChange: (datum, value, user_data)=>{},
    },
    exchanger_id : { type: 'int', is_hidden: true, },
    exchanger    : {
      type: 'view',
      view: (datum, user_data) => '',
      present : (datum, user_data) => {
        return `${datum.exchanger.id}: ${datum.exchanger.name}`;
      },
      onChange: (datum, value, user_data)=>{},
    },
    from: {
      type: 'view',
      view: (datum, user_data) => '',
      present : (datum, user_data) => {
        return `${string_util.makeCommaedString(datum.from_token_amount, 4).replace(/\./, ' .')} ${datum.from_token_symbol}`;
      },
      onChange: (datum, value, user_data)=>{},
    },
    to: {
      type: 'view',
      view: (datum, user_data) => '',
      present : (datum, user_data) => {
        return `${string_util.makeCommaedString(datum.to_token_amount, 4).replace(/\./, ' .')} ${datum.to_token_symbol}`;
      },
      onChange: (datum, value, user_data)=>{},
    },
    commission_rate_for_me: {
      type: 'view',
      view: (datum, user_data) => '',
      present : (datum, user_data) => {
        const commission_rate = datum.commission_rate_for_owner * 100.0;
        return `${string_util.removeTails(string_util.makeCommaedString(commission_rate, 3), ['.', '0'])} %`;
      },
      onChange: (datum, value, user_data)=>{},
    },
    commission_amount_for_me: {
      type: 'view',
      view: (datum, user_data) => '',
      present : (datum, user_data) => {
        const commission_amount = parseFloat(datum.commission_amount_for_owner);
        return `${string_util.makeCommaedString(commission_amount, 4).replace('.', ' .')} ${datum.from_token_symbol}`;
      },
      onChange: (datum, value, user_data)=>{},
    },
  },

  lang_dic: {
    id: 'id',
    created: '생성일',
    user: '유저',
    exchanger: '지점',
    from: '화폐량',
    to: '코인량',
    commission_rate_for_me: '수수료율',
    commission_amount_for_me: '수수료',

    total_commission_rate: '총 수수료율',
    total_commission_amount: '총 수수료',
    my_commission_rate: '내 수수료율',
    my_commission_amount: '내 수수료',
  }
}
