import $ from 'jquery';
import React, { Component } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { ToastContainer, toast } from 'react-toastify';

import YesNoModal          from '../../snippets/YesNoModal';
import AdminTemplateEngine from '../../util/admin_template_engine';
import common              from '../../util/common';
import MountChecker        from '../../util/mount_checker';
import server_api          from '../../util/server_api';
import TableView           from '../common/TableView';
import token_to_money_data from './token_to_money_data_format';




class TokenToMoney extends Component {
	constructor(props) {
    super(props);
    
    this.state = {
      is_loading: false,
      dirty_no: 0,
      cancel_log_for_check_modal: '',
    };

    this.ate_ = new AdminTemplateEngine(token_to_money_data.data_format, {}, token_to_money_data.lang_dic);
    
    this.mount_checker_ = new MountChecker();

    this.items_ = [];
    this.id_item_map_ = {};
    this.on_post_fetch_ = ()=>{};
	}
  
  componentDidMount () {
    this.mount_checker_.onComponentDidMount();
  }

  componentWillUnmount () {
    this.mount_checker_.onComponentWillUnmount();
  }
	
	render () {

		return (
      <LoadingOverlay
        active={this.state.is_loading}
        spinner
        text='Loading ...'
      >
        <div style={{minHeight: '60rem'}}>
          <TableView
            isCreatable={false}
            dirty_no={this.state.dirty_no}
            path="/token-to-money"
            idExpForCount="TokenToMoney.id"
            editPath=""
            adminTemplateEngine={this.ate_}
            tailMaker={(ri, row)=>this._tailMaker(ri, row)}
            fetchFunc={server_api.fetchTokenToMoneys}
            reviseFetchParams={(params, options)=>this._onReviseFetchParams(params, options)}
            postFetch={(items)=>this._postFetch(items)}
            sorttypeOrdermakerMap={{
              'id 오름차순'   : (order) => order.push(['id', 'ASC' ]),
              'id 내림차순'  : (order) => order.push(['id', 'DESC']),
            }}
            limittypeLimitMap={{
              '10개씩' :  10,
              '30개씩' :  30,
              '50개씩' :  50,
              '100개씩': 100,
            }}
            additionalOptionsMakerDataMap={{
              '상태': {
                items: ['전체', '진행중', '완료됨', '취소됨'],
                maker: (options, value) => {
                  if (!options.where)
                    options.where = {};
                  switch (value) {
                    case '진행중':
                      options.where.status = {
                        '$notIn': ['OK', 'CANCELED', 'FAILED'],
                      };
                      break;
                    case '완료됨':
                      options.where.status = 'OK';
                      break;
                    case '취소됨':
                      options.where.status = 'CANCELED';
                      break;
                  }
                },
              },
            }}
            searchkeyWheremakerMap={{
              'id'           : (where, include, keyword) => where['id'     ] = keyword,
              '유저 아이디': (where, include, keyword) => {
                include.push({
                  required: true,
                  association: 'user',
                  where: { login_id: keyword, },
                });
              },
              '지점 아이디': (where, include, keyword) => {
                include.push({
                  required: true,
                  association: 'exchanger',
                  where: { login_id: keyword, },
                });
              },
            }}
            querykeyDefaultMap={{
              page        : 1,
              limit_type  : '10개씩',
              sort_type   : 'id 내림차순',
              search_key  : '유저 아이디',
              search_value: '',
              additional: {
                '상태': '전체',
              },
            }}
            orderTitle ="정렬"
            limitTitle ="제한"
            searchTitle="검색"
            applyTitle ="적용"
            addTitle   ="추가"
            editTitle  ="수정"
          />
        </div>
				<YesNoModal
					id="cancel_log_check_modal"
          message={this.state.cancel_log_for_check_modal}
          needNo={false}
          yesStr="Ok"
				/>
      </LoadingOverlay>
		);
  }
  
	_onReviseFetchParams (params, options) {
    params.include = params.include.concat([
      { association: 'user'                 , attributes: ['id', 'login_id'], },
      { association: 'user_bankaccount'     , include: ['bank'], },
      { association: 'exchanger'            , attributes: ['id', 'name', 'login_id'], },
      { association: 'exchanger_bankaccount', include: ['bank'], },
    ]);

		return {params, options};
  }

  _tailMaker (ri, row) {
    if (ri === null)
      return [ '상태' ];
    
    const id = row[0];
    const item = this.id_item_map_[id];
    if (!item)
      return [''];
    const cur_status = item.status;
    
    const status_kor_map = {
      START: '시작',
      EXCHANGABLE: '거래 가능',
      PAY_CONFIRMED: '입금 확인',
    };

    const status_jsx =
      (cur_status === 'OK'      )? <button type="button" className="w-100 btn btn-sm btn-success" disabled>완료됨</button>:
      (cur_status === 'FAILED'  )? <button type="button" className="w-100 btn btn-sm btn-danger"  disabled>실패함</button>:
      (cur_status === 'CANCELED')? this._makeCancelBtnJsx(item):
        <div className="d-flex">
          {
            ['START', 'EXCHANGABLE'].map((st) => {
              return (
                <span
                  key={st}
                  className={`mx-1 badge ${(st===cur_status)? 'badge-primary':'badge-light'}`}
                >
                  {status_kor_map[st]}
                </span>
              );
            })
          }
        </div>;

    return [
      status_jsx,
    ];
  }


  async _postFetch (items) {
    this.items_ = items;
    this.id_item_map_ = {};
    items.forEach((it)=>this.id_item_map_[it.id]=it);
    
    await this.on_post_fetch_();
    this.on_post_fetch_ = ()=>{};
  }

  _makeCancelBtnJsx (item) {
    const MAX_LEN = 20;
    return (
      <button
        type="button"
        className="w-100 btn btn-sm btn-warning"
        onClick={() => {
          const cancel_log = item.cancel_log || '';
          this.setState({
            cancel_log_for_check_modal: cancel_log,
          });
          $('#cancel_log_check_modal').modal({backdrop: 'static', keyboard: false});
        }}
      >
        <div key="title">취소됨</div>
        {
          (!item.cancel_log || item.cancel_log === '')? null:
            <div>
              {
                (item.cancel_log.length < MAX_LEN)?
                  item.cancel_log:
                  (item.cancel_log.substr(0, MAX_LEN-3)+'...')
              }
            </div>
        }
      </button>
    );
  }

}


export default TokenToMoney;