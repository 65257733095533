import React, { Component } from 'react';
import $                    from 'jquery';

import AdminTemplateEngine from '../../util/admin_template_engine';
import common              from '../../util/common';
import MountChecker        from '../../util/mount_checker';
import server_api          from '../../util/server_api';
import TableEdit           from '../common/TableEdit';
import administrator_data   from './administrator_data_format';


class AdministratorEdit extends Component {
	constructor(props) {
		super(props);
		
		this.ate_ = new AdminTemplateEngine(
      administrator_data.data_format,
      {},
			administrator_data.lang_dic,
		);

		this.mount_checker_ = new MountChecker();
	}

	componentDidMount () {
		this.mount_checker_.onComponentDidMount();
	}

	componentWillUnmount () {
		this.mount_checker_.onComponentWillUnmount();
	}

	render () {
		return (
			<TableEdit
				redirectPath="/administrator"
				adminTemplateEngine={this.ate_}
        fetchFunc  ={server_api.fetchAdministrators}
				createFunc ={(params, options)=>this._createFunc(params, options)}
				updateFunc ={(id, params, options)=>this._updateFunc(id, params, options)}
				deleteFunc ={server_api.deleteAdministrator}
				addTitle    ="추가"
				confirmTitle="확정"
				deleteTitle ="삭제"
			/>
		);
	}
	
	async _createFunc (params, options) {
		return await server_api.createAdministrator(params, options);
	}
	
	async _updateFunc (id, params, options) {
		return await server_api.updateAdministrator(id, params, options);
	}

}

export default AdministratorEdit;