import AdminTemplateEngine from "../../util/admin_template_engine";
import s3_util from '../../util/s3_util';

export default {
  data_format: {
    id      : { type: "int", is_readonly: true, },
    name    : { type: 'string'  , is_readonly: true, },
    value   : { type: 'string'  , validate: AdminTemplateEngine.validateNotEmpty, },
    value2  : { type: 'string'  , },
  },

  lang_dic: {},
}
