import date_util from './date_util';
import s3_util from './s3_util';
import server_api from './server_api';
import string_util from './string_util';

export default {
	id: null,
	role: null,
	token: null,
	country_code: 'US',

	isLoggedIn(on_check_token_err = () => {}) {
		const common_data_str = localStorage.getItem('common_data');
		if (common_data_str === null || !JSON.parse(common_data_str).token) {
			on_check_token_err();
			return false;
		}

		(async () => {
			const is_ok = await this.checkToken();
			if (is_ok === false) on_check_token_err();
		})();

		return true;
	},

	async checkToken() {
		const common_data_str = localStorage.getItem('common_data') || '{}';
		const common_data = JSON.parse(common_data_str);
		if (!common_data.token) return false;

		Object.keys(common_data).map((k) => (this[k] = common_data[k]));

		const res = await fetch(server_api.api_server_address2 + '/api/auth/check-token', {
			method: 'POST',
			body: JSON.stringify({
				id: this.id,
				role: this.role,
				token: this.token
			})
		});
		if (Math.floor(res.status / 100) !== 2) {
			this.logout();
			return false;
		}

		return true;
	},

	logout() {
		localStorage.removeItem('common_data');
	},

	async login(login_id, password) {
		console.log(server_api.api_server_address2 + '/api/auth/login');
		const res = await fetch(server_api.api_server_address2 + '/api/auth/login', {
			method: 'POST',
			body: JSON.stringify({
				type: 'ADMIN',
				login_id,
				password
			})
		});
		const res_json = await res.json();

		if (Math.floor(res.status / 100) !== 2) {
			this.logout();
			return res.status;
		}

		const item = res_json.items[0];

		const common_data = {};
		common_data.id = item.id;
		common_data.role = item.role;
		common_data.token = item.token;
		common_data.country_code = item.country_code;

		localStorage.setItem('common_data', JSON.stringify(common_data));
		Object.keys(common_data).map((k) => (this[k] = common_data[k]));

		return res.status;
	},

	saveToLocalStorage() {
		localStorage.setItem('common_data', JSON.stringify(this));
	},

	loadFromLocalStorage() {
		const common_data_str = localStorage.getItem('common_data') || '{}';
		const common_data = JSON.parse(common_data_str);
		Object.keys(common_data).map((k) => (this[k] = common_data[k]));
	},

	goBackIfUnavailableRole(roles) {
		if (roles.indexOf(this.role) < 0) return window.history.back();
	}
};
