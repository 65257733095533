
import string_util from './string_util';


export default {
  makeYearMonthWeek (dt) {
    const ymd = string_util.makeYmd(dt);
    dt = new Date(ymd);

    const next_month_first_dt = new Date(ymd.split('-').slice(0, 2).join('-'));
    next_month_first_dt.setMonth(next_month_first_dt.getMonth() + 1);
    const next_month_first_week_dt = this.getFirstWeekDate(next_month_first_dt);
    if (dt.getTime() >= next_month_first_week_dt.getTime())
      return [...(string_util.makeYmd(next_month_first_dt).split('-').slice(0, 2)), 'w1'].join('-');


    const first_week_dt = this.getFirstWeekDate(new Date(ymd.split('-').slice(0, 2).join('-')));

    const delta_date = Math.floor((dt.getTime() - first_week_dt.getTime()) / (24*60*60*1000));
    if (delta_date < 0) {
      // last week of a month before
      const last_dt = new Date(ymd.split('-').slice(0, 2).join('-'));
      last_dt.setDate(last_dt.getDate() - 1);
      return this.makeYearMonthWeek(last_dt);
    }

    const week_idx = Math.floor(delta_date / 7);

    return [...(ymd.split('-').slice(0, 2)), 'w'+(week_idx+1)].join('-');
  },

  getFirstWeekDate (dt) {
    const first_dt = new Date(dt.getFullYear(), dt.getMonth());
    const first_dt_day = first_dt.getDay();
    if ([1, 2, 3, 4].indexOf(first_dt_day) >= 0)
      first_dt.setDate(first_dt.getDate() - (first_dt_day - 1));
    else
      first_dt.setDate(first_dt.getDate() + (8 - first_dt_day));
    return first_dt;
  },

  getFirstMonthDate (dt) {
    return new Date(dt.getFullYear(), dt.getMonth());
  },

  makeYmdWithDelta (ymd, delta_year, delta_month, delta_date) {
    const dt = new Date(ymd);

    dt.setFullYear(dt.getFullYear() + delta_year );
    dt.setMonth   (dt.getMonth   () + delta_month);
    dt.setDate    (dt.getDate    () + delta_date );

    return string_util.makeYmd(dt);
  },
  makeYmdListWithDelta (ymd, delta_year, delta_month, delta_date) {
    const other = this.makeYmdWithDelta(ymd, delta_year, delta_month, delta_date);
    const [from_ymd, to_ymd] = (ymd < other)? [ymd, other]: [other, ymd];
    return this.makeYmdList(from_ymd, to_ymd);
  },
  makeYmdList (first_ymd, last_ymd) {
    const result = [];
    for (let ymd=first_ymd; ymd<=last_ymd; ymd=this.makeYmdWithDelta(ymd, 0, 0, 1))
      result.push(ymd);
    return result;
  },
};