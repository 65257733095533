import React, { Component } from 'react';

import AdminTemplateEngine from '../../util/admin_template_engine';
import common              from '../../util/common';
import MountChecker        from '../../util/mount_checker';
import server_api          from '../../util/server_api';
import TableView           from '../common/TableView';
import bank_data           from './bank_data_format';




class Bank extends Component {
	constructor(props) {
    super(props);
    
    this.state = {
      brands: [],
    };

    this.ate_ = new AdminTemplateEngine(bank_data.data_format, {}, bank_data.lang_dic);
    
    this.mount_checker_ = new MountChecker();
	}
  
  componentDidMount () {
    this.mount_checker_.onComponentDidMount();
  }

  componentWillUnmount () {
    this.mount_checker_.onComponentWillUnmount();
  }
	
	render () {

		return (
      <div style={{minHeight: '60rem'}}>
        <TableView
          path="/bank"
          idExpForCount="Banks.id"
          adminTemplateEngine={this.ate_}
          fetchFunc={server_api.fetchBanks}
          sorttypeOrdermakerMap={{
            'id 오름차순'   : (order) => order.push(['id', 'ASC' ]),
            'id 내림차순'  : (order) => order.push(['id', 'DESC']),
            '이름 오름차순' : (order) => order.push(['name', 'ASC' ]),
            '이름 내림차순': (order) => order.push(['name', 'DESC']),
          }}
          limittypeLimitMap={{
            '10개씩' :  10,
            '30개씩' :  30,
            '50개씩' :  50,
            '100개씩': 100,
          }}
          searchkeyWheremakerMap={{
            'id'  : (where, include, keyword) => where['id'  ] = keyword,
            '이름': (where, include, keyword) => where['name'] = {'$like': `%${keyword}%`},
          }}
          querykeyDefaultMap={{
            page        : 1,
            limit_type  : '10개씩',
            sort_type   : 'id 오름차순',
            search_key  : '이름',
            search_value: '',
          }}
          orderTitle ="정렬"
          limitTitle ="제한"
          searchTitle="검색"
          applyTitle ="적용"
          addTitle   ="추가"
          editTitle  ="수정"
        />
      </div>
		);
  }
  
}


export default Bank;