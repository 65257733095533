import React, { Component } from 'react';
import $                    from 'jquery';

import AdminTemplateEngine from '../../util/admin_template_engine';
import common              from '../../util/common';
import MountChecker        from '../../util/mount_checker';
import server_api          from '../../util/server_api';
import TableEdit           from '../common/TableEdit';
import exchanger_data      from './exchanger_data_format';


class AdministratorEdit extends Component {
	constructor(props) {
		super(props);
		
		this.ate_ = new AdminTemplateEngine(
      exchanger_data.data_format,
      {},
			exchanger_data.lang_dic,
		);

		this.mount_checker_ = new MountChecker();
	}

	componentDidMount () {
		this.mount_checker_.onComponentDidMount();
	}

	componentWillUnmount () {
		this.mount_checker_.onComponentWillUnmount();
	}

	render () {
		return (
			<TableEdit
				redirectPath="/exchanger"
				adminTemplateEngine={this.ate_}
        reviseFetchParams={(params, options)=>this._reviseFetchParams(params, options)}
        fetchFunc  ={server_api.fetchExchangers}
				createFunc ={(params, options)=>this._register(params, options)}
				updateFunc ={server_api.updateExchanger}
				deleteFunc ={server_api.deleteExchanger}
				onFetchData={()=>this._onFetchData()}
				reviseDatum={(datum=>this._onReviseDatum(datum))}
				addTitle    ="추가"
				confirmTitle="확정"
				deleteTitle ="삭제"
			/>
		);
	}
	

	async _onFetchData () {
		const admins = (await server_api.fetchAllAdministrators({})).items;
		this.mount_checker_.throwWhenUnmounted();

		this.ate_.setUserdata(
			Object.assign(
				{},
				this.ate_.getUserdata(),
				{	admins,	},
			)
		);
  }
  
  async _reviseFetchParams (params, options) {
		params.include.push({
			association: 'parent_admin',
		})
    return {params, options};
  }
	
	async _register (params, options) {
		return await server_api.registerExchanger(params);
	}
	
	async _onReviseDatum (datum) {
    const result = Object.assign({}, datum);
		result.is_archived = false;
		result.parent_admin_id = (result.parent_admin === undefined)? JSON.parse(result.parent_admin).id: common.id;
		delete result.parent_admin;
		return result;
	}
}

export default AdministratorEdit;