import React, { Component } from "react";

import AdminTemplateEngine from "../../util/admin_template_engine";
import common from "../../util/common";
import MountChecker from "../../util/mount_checker";
import server_api from "../../util/server_api";
import TableView from "../common/TableView";
import user_data from "./user_data_format";
import $ from "jquery";

class User extends Component {
  constructor(props) {
    super(props);

    this.state = {
      brands: [],
    };

    this.ate_ = new AdminTemplateEngine(
      user_data.data_format,
      {},
      user_data.lang_dic
    );

    this.mount_checker_ = new MountChecker();
  }

  componentDidMount() {
    this.mount_checker_.onComponentDidMount();
  }

  componentWillUnmount() {
    this.mount_checker_.onComponentWillUnmount();
  }

  _fetchHim(_address, _id) {
    fetch(
      server_api.api_server_address2 + "/api/users/" + _address + "/account",
      {
        headers: { "x-api-key": common.token },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        let i = json.items;
        console.log(i);
        let c = "0 HIM";
        c = i.join(" / ");
        $("#" + _id).html(c);
      });
  }

  _tailMaker(ri, row) {
    if (ri === null) {
      return ["HIM / GPP / GPPG"];
    } else {
      this._fetchHim(row[0], ri);
      return [
        <a href={"/account?search_key=유저아이디&search_value=" + row[0]}>
          <div id={ri}></div>
        </a>,
      ];
    }
  }

  render() {
    return (
      <div style={{ minHeight: "60rem" }}>
        <TableView
          path="/user"
          idExpForCount="Users.id"
          tailMaker={(ri, row) => this._tailMaker(ri, row)}
          adminTemplateEngine={this.ate_}
          isCreatable={false}
          fetchFunc={server_api.fetchUsers}
          sorttypeOrdermakerMap={{
            "id 오름차순": (order) => order.push(["id", "ASC"]),
            "id 내림차순": (order) => order.push(["id", "DESC"]),
            "로그인 아이디 오름차순": (order) =>
              order.push(["login_id", "ASC"]),
            "로그인 아이디 내림차순": (order) =>
              order.push(["login_id", "DESC"]),
            "가입일 오름차순": (order) => order.push(["createdAt", "ASC"]),
            "가입일 내림차순": (order) => order.push(["createdAt", "DESC"]),
          }}
          limittypeLimitMap={{
            "2개씩": 2, // for test
            "10개씩": 10,
            "30개씩": 30,
            "50개씩": 50,
            "100개씩": 100,
          }}
          searchkeyWheremakerMap={{
            id: (where, include, keyword) => (where["id"] = keyword),
            "로그인 아이디": (where, include, keyword) =>
              (where["login_id"] = { $like: `%${keyword}%` }),
            "eos 계정": (where, include, keyword) =>
              (where["eos_account"] = keyword),
          }}
          querykeyDefaultMap={{
            page: 1,
            limit_type: "10개씩",
            sort_type: "id 오름차순",
            search_key: "로그인 아이디",
            search_value: "",
          }}
          orderTitle="정렬"
          limitTitle="제한"
          searchTitle="검색"
          applyTitle="적용"
          addTitle="추가"
          editTitle="수정"
        />
      </div>
    );
  }
}

export default User;
