import React, { Component } from 'react';

import AdminTemplateEngine from '../../util/admin_template_engine';
import common              from '../../util/common';
import MountChecker        from '../../util/mount_checker';
import server_api          from '../../util/server_api';
import TableView           from '../common/TableView';
import notice_data         from './notice_data_format';




class Notice extends Component {
	constructor(props) {
    super(props);
    
    this.state = {
      brands: [],
    };

    this.ate_ = new AdminTemplateEngine(notice_data.data_format, {}, notice_data.lang_dic);
    
    this.mount_checker_ = new MountChecker();
	}
  
  componentDidMount () {
    this.mount_checker_.onComponentDidMount();
  }

  componentWillUnmount () {
    this.mount_checker_.onComponentWillUnmount();
  }
	
	render () {

		return (
      <div style={{minHeight: '60rem'}}>
        <TableView
          path="/notice"
          idExpForCount="Board_Post.id"
          adminTemplateEngine={this.ate_}
          fetchFunc={server_api.fetchBoardPosts}
          reviseFetchParams={(params, options)=>this._onReviseFetchParams(params, options)}
          sorttypeOrdermakerMap={{
            'id 오름차순'   : (order) => order.push(['id', 'ASC' ]),
            'id 내림차순'  : (order) => order.push(['id', 'DESC']),
            '제목 오름차순' : (order) => order.push(['title', 'ASC' ]),
            '제목 내림차순': (order) => order.push(['title', 'DESC']),
          }}
          limittypeLimitMap={{
            '10개씩' :  10,
            '30개씩' :  30,
            '50개씩' :  50,
            '100개씩': 100,
          }}
          searchkeyWheremakerMap={{
            'id'  : (where, include, keyword) => where['id'   ] = keyword,
            '제목': (where, include, keyword) => where['title'] = {'$like': `%${keyword}%`},
          }}
          querykeyDefaultMap={{
            page        : 1,
            limit_type  : '10개씩',
            sort_type   : 'id 오름차순',
            search_key  : '제목',
            search_value: '',
          }}
          orderTitle ="정렬"
          limitTitle ="제한"
          searchTitle="검색"
          applyTitle ="적용"
          addTitle   ="추가"
          editTitle  ="수정"
        />
      </div>
		);
  }
  
  _onReviseFetchParams (params, options) {
    params.where = { board_id: 1, };
    return { params, options };
  }

}


export default Notice;