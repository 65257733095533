import React, { Component } from 'react';
import LoadingOverlay       from 'react-loading-overlay';
import { toast }            from 'react-toastify';
import querystring          from 'querystring';

import AdminTemplateEngine from '../../util/admin_template_engine';
import common              from '../../util/common';
import MountChecker        from '../../util/mount_checker';
import server_api          from '../../util/server_api';
import TableEdit           from '../common/TableEdit';
import notice_data         from './notice_data_format';


class NoticeEdit extends Component {
	constructor(props) {
		super(props);

		this.mount_checker_ = new MountChecker();
		this.state = {
			is_loading: false,

			is_add_mode: false,
			id: null,
			title: '',
			content: '',
		};
	}

	componentDidMount () {
		this.mount_checker_.onComponentDidMount();

		this._fetchDataAndRefresh();
	}

	componentWillUnmount () {
		this.mount_checker_.onComponentWillUnmount();
	}

	render () {
		return (
			
      <LoadingOverlay
        active={this.state.is_loading}
        spinner
        text='Loading ...'
      >
				<div className="d-flex flex-column align-items-center p-0 m-0 pt-4 px-0 mb-5" style={{minHeight: '50rem'}}>
					<div className="w-100 p-0 m-0">
						<div className="row justify-content-center mx-0">
							<div className="col-12 col-md-9 col-lg-6 p-0">

								<div className="p-2 m-1 d-flex align-items-center">
									<div className="mr-3 text-right" >
										제목
									</div>
									<div className="flex-grow-1">
										<input
											className="form-control"
											value={this.state.title}
											onChange={(e)=>this.setState({title: e.target.value})}
										/>
									</div>
								</div>

								<textarea
									className="form-control mt-4"
									rows="20"
									value={this.state.content}
									onChange={(e)=>this.setState({content: e.target.value})}
								/>
								
								<div className="mt-3 d-flex justify-content-end">
									{
										(this.state.is_add_mode)?
											<button className="mr-2 btn btn-primary" onClick={()=>this._onAdd()}>추가</button>:
											[
												<button key="save" className="mr-2 btn btn-secondary" onClick={()=>this._onSave()}>저장</button>,
												<button key="delete" className="btn btn-danger" onClick={()=>this._onDelete()}>삭제</button>
											]
									}
								</div>

							</div>
						</div>
					</div>
				</div>
			</LoadingOverlay>
		);
	}

	async _onAdd () {
		this.setState({ is_loading: true, });

		try {
			const new_post = (await server_api.createBoardPost({
				board_id: 1,
				title: this.state.title,
			})).items[0];

			await server_api.writeBoardPostContent(new_post.id, this.state.content);

		}catch (e) {
			toast.error(JSON.stringify(e));
			return this.setState({ is_loading: false, });
		}
		
		this.props.history.push('/notice');
	}

	async _onSave () {
		this.setState({ is_loading: true, });

		try {
			await server_api.updateBoardPost(
				this.state.id,
				{ title: this.state.title, },
			);

			await server_api.writeBoardPostContent(this.state.id, this.state.content);

		}catch (e) {
			toast.error(JSON.stringify(e));
			return this.setState({ is_loading: false, });
		}
		
		this.props.history.push('/notice');
	}

	async _onDelete () {
		this.setState({ is_loading: true, });
		
		try {
			await server_api.deleteBoardPost(this.state.id);

		}catch (e) {
			toast.error(JSON.stringify(e));
			return this.setState({ is_loading: false, });
		}
		
		this.props.history.push('/notice');
	}

	async _fetchDataAndRefresh () {
		const query = querystring.parse(window.location.search.substr(1));
		const id = query.id;
		if (!id) {
			return this.setState({
				is_add_mode: true,
				id: null,
				title: '',
				content: '',
			});
		}

		this.setState({ is_loading: true, });
		
		const post = (await server_api.fetchBoardPosts({
			where: { id, },
			include: [
				{ association: 'contents', },
			],
		})).items[0];

		let title   = '';
		let content = '';
		if (post) {
			title = post.title;
			const contents = post.contents.filter((c)=>c.content_type==='TEXT');
			contents.sort((a,b)=>(a.order<b.order)?-1:1);
			if (contents[0])
				content = contents[0].content;
		}

		this.setState({
			is_loading: false,
			id,
			title,
			content,
		});
	}

}

export default NoticeEdit;