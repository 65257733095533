import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import uuid from 'uuid/v4';


class UuidInput extends Component {
  constructor (props) {
    super(props);

    this.state = {
      value: props.value,
    }
  }

	render () {
		return (
      <div className="d-flex">
        <input type="text" className="form-control flex-grow-1" value={this.state.value} readOnly />
        <button className="btn btn-primary" onClick={()=>this._onClick()} readOnly={this.props.readOnly} >regen</button>
      </div>
		);
  }

  _onClick () {
    const new_uuid = uuid();
    this.props.onChange(new_uuid);
    this.setState({
      value: new_uuid,
    });
  }

}

UuidInput.defaultProps = {
  value: '',
  readOnly: false,
  onChange: (v)=>{},
};

export default UuidInput;