import jsxToString from "jsx-to-string";
import query_string from "query-string";
import React, { Component } from "react";
import { CSVLink } from "react-csv";
import { Link, withRouter } from "react-router-dom";

import DataTable from "../../snippets/DataTable";
import Pagination from "../../snippets/Pagination";
import SearchableDropdown from "../../snippets/SearchableDropdown.js";
import AdminTemplateEngine from "../../util/admin_template_engine.js";
import MountChecker from "../../util/mount_checker";

class TableView extends Component {
  constructor(props) {
    super(props);

    this.is_fetching_ = false;
    this.dirty_no_ = -1;

    const additional_options_type_data_map = {};
    for (const key in props.additionalOptionsMakerDataMap) {
      const data = props.additionalOptionsMakerDataMap[key];
      additional_options_type_data_map[key] = {
        items: data.items,
        selected_item: props.querykeyDefaultMap.additional[key],
      };
    }

    this.state = {
      fields: this.props.adminTemplateEngine.makeFields(),
      items: undefined,

      sort_type_data: {
        items: Object.keys(this.props.sorttypeOrdermakerMap).map((k) =>
          this._getFromLangDic(k)
        ),
        selected_item: this.props.querykeyDefaultMap.sort_type,
      },

      limit_type_data: {
        items: Object.keys(this.props.limittypeLimitMap).map((k) =>
          this._getFromLangDic(k)
        ),
        selected_item: this.props.querykeyDefaultMap.limit_type,
      },

      additional_options_type_data_map,

      searchable_fields_data: {
        items: Object.keys(this.props.searchkeyWheremakerMap).map((k) =>
          this._getFromLangDic(k)
        ),
        selected_item: this.props.querykeyDefaultMap.search_key,
      },
      search_keyword: "",

      page_count: 1,

      page: this.props.querykeyDefaultMap.page,
      limit_type: this.props.querykeyDefaultMap.limit_type,
      sort_type: this.props.querykeyDefaultMap.sort_type,
      search_key: this.props.querykeyDefaultMap.search_key,
      search_value: this.props.querykeyDefaultMap.search_value,

      csv_data: [],
    };

    this.mount_checker_ = new MountChecker();
  }

  componentDidMount() {
    this.mount_checker_.onComponentDidMount();
    this._fetchData(this.state);
  }

  componentWillUnmount() {
    this.mount_checker_.onComponentWillUnmount();
  }

  componentWillUpdate(nextProps, nextState) {
    const query = this._parseQuery();

    if (nextProps.dirty_no > this.dirty_no_) {
      this.dirty_no_ = nextProps.dirty_no;
      return this._fetchData(nextState, false);
    }

    for (const key in query) {
      const next_value =
        key.substr(0, 11) !== "additional."
          ? nextState[key]
          : nextState[`additional.${key.substr(11)}`];

      if (query[key] !== next_value) {
        return this._fetchData(nextState);
      }
    }
  }

  render() {
    return (
      <div className="d-flex flex-column">
        <div className="w-100" style={{ backgroundColor: "#e6e6e6" }}>
          <div className="w-100 mx-2 row">
            <div className="mt-3">
              <div className="d-flex align-items-center">
                <div className="mr-2 text-right" style={{ width: "4rem" }}>
                  {this.props.orderTitle}
                </div>
                <SearchableDropdown
                  className="mr-5"
                  btnClassName={"btn-light"}
                  data={this.state.sort_type_data}
                  style={{ minWidth: "15rem" }}
                />
              </div>
            </div>

            <div className="mt-3">
              <div className="d-flex align-items-center">
                <div className="mr-2 text-right" style={{ width: "4rem" }}>
                  {this.props.limitTitle}
                </div>
                <SearchableDropdown
                  className="mr-5"
                  btnClassName={"btn-light"}
                  data={this.state.limit_type_data}
                  style={{ minWidth: "8rem" }}
                />
              </div>
            </div>

            {Object.keys(this.state.additional_options_type_data_map).map(
              (key) => {
                const data = this.state.additional_options_type_data_map[key];
                return (
                  <div key={key} className="mt-3">
                    <div className="d-flex align-items-center">
                      <div className="mr-2">{key}</div>
                      <SearchableDropdown
                        className="mr-5"
                        btnClassName={"btn-light"}
                        data={data}
                        style={{ minWidth: "8rem" }}
                      />
                    </div>
                  </div>
                );
              }
            )}
          </div>
          <div className="w-100 mx-2 my-3 p-0 row align-items-center">
            <div className="mr-2 mt-3 text-right" style={{ width: "4rem" }}>
              {this.props.searchTitle}
            </div>
            <div className="mr-2 mt-3" style={{ width: "12rem" }}>
              <SearchableDropdown data={this.state.searchable_fields_data} />
            </div>
            <div className="mt-3 d-flex align-items-center">
              <input
                type="text"
                className="form-control mr-2"
                value={this.state.search_keyword}
                onChange={(e) => {
                  this.setState({ search_keyword: e.target.value });
                }}
              />
              <button
                className="btn btn-dark"
                onClick={() => this._onSearch()}
                style={{ width: "8rem" }}
              >
                {this.props.applyTitle}
              </button>
            </div>
            <div className="flex-grow-1"></div>
            {this.props.beforeAddBtn}
            {this.props.isCsvDownloadable === false ? null : (
              <CSVLink
                className={
                  "mx-3 mt-3 btn btn-info" +
                  (this.state.items ? "" : " disabled")
                }
                filename={this.props.csvFilename}
                data={this.state.csv_data}
                onClick={() => this._onDownloadCsv()}
              >
                {this.props.downloadCsvTitle}
              </CSVLink>
            )}
            {this.props.isCreatable === false ? null : (
              <button
                type="button"
                className="d-none d-md-inline mt-3 mx-3 px-4 btn btn-primary"
                onClick={() => this._onAdd()}
              >
                {this.props.addTitle}
              </button>
            )}
          </div>
          {this.props.isCreatable === false ? null : (
            <div className="d-flex d-md-none m-3 justify-content-end">
              <button
                type="button"
                className="px-4 btn btn-primary"
                onClick={() => this._onAdd()}
              >
                {this.props.addTitle}
              </button>
            </div>
          )}
        </div>

        <div className="mx-2 my-2">
          <div className="w-100" style={{ overflow: "scroll" }}>
            <DataTable
              editPath={
                this.props.editPath !== undefined
                  ? this.props.editPath
                  : this.props.path + "/edit"
              }
              headMaker={this.props.headMaker}
              tailMaker={this.props.tailMaker}
              fields={this.state.fields}
              rows={this.props.adminTemplateEngine.makeTableRows(
                this.state.items
              )}
              editTitle={this.props.editTitle}
            />
          </div>
        </div>

        <div className="my-2 d-flex justify-content-center">
          <Pagination
            count={this.state.page_count}
            showLimit={7}
            data={{
              current: this.state.page,
            }}
            onChange={(page_no) => this._pushHistory({ page: page_no })}
          />
        </div>
      </div>
    );
  }

  _onDownloadCsv() {
    const fields = [].concat(this.state.fields);
    const rows = this.props.adminTemplateEngine.makeTableRows(this.state.items);
    rows.forEach((r) =>
      r.forEach(
        (d, ri) =>
          (r[ri] =
            d["$$typeof"] === Symbol.for("react.element") ? jsxToString(d) : d)
      )
    );
    this.props.reviseDataOfCsv(this.state.items, fields, rows);

    const csv_data = [];
    csv_data.push(fields);
    rows.forEach((r) => csv_data.push(r));
    this.setState({ csv_data });
  }

  _onAdd() {
    if (this.props.onAdd === undefined)
      return this.props.history.push(this.props.path + "/edit");

    this.props.onAdd();
  }

  _onSearch() {
    const additional_query = {};
    for (const key in this.state.additional_options_type_data_map) {
      const maker_data = this.state.additional_options_type_data_map[key];
      additional_query[`additional.${key}`] = maker_data.selected_item;
    }

    this._pushHistory({
      sort_type: this.state.sort_type_data.selected_item,
      limit_type: this.state.limit_type_data.selected_item,
      search_key: this.state.searchable_fields_data.selected_item,
      search_value: this.state.search_keyword,
      ...additional_query,
    });
  }

  _pushHistory(query) {
    const parsed = this._parseQueryWithCurrent(query);
    this.props.history.push(
      this.props.path + "?" + query_string.stringify(parsed)
    );
  }

  _parseQuery() {
    const query = query_string.parse(window.location.search);

    let { additional: additional_default_map, ...key_default_map } =
      this.props.querykeyDefaultMap;
    for (const key in additional_default_map)
      key_default_map[`additional.${key}`] = additional_default_map[key];

    const result = {};
    for (const key in key_default_map) {
      if (query[key] === undefined) {
        result[key] = key_default_map[key];
        continue;
      }

      const default_value = key_default_map[key];
      switch (typeof default_value) {
        case "object":
          result[key] = JSON.parse(query[key]);
          break;
        case "number":
          result[key] = Number.isInteger(default_value)
            ? parseInt(query[key])
            : parseFloat(query[key]);
          break;
        case "boolean":
          result[key] = query[key] === "true" ? true : false;
          break;
        default:
          result[key] = query[key];
          break;
      }
    }
    return result;
  }

  _parseQueryWithCurrent(query) {
    let { additional: additional_default_map, ...key_default_map } =
      this.props.querykeyDefaultMap;
    for (const key in additional_default_map)
      key_default_map[`additional.${key}`] = additional_default_map[key];

    const result = {};
    for (const key in key_default_map) {
      if (query[key] === undefined) {
        if (key.substr(0, 11) === "additional.")
          result[key] =
            this.state.additional_options_type_data_map[
              key.substr(11)
            ].selected_item;
        else result[key] = this.state[key];
      } else result[key] = query[key];
    }

    return result;
  }

  _getFromLangDic(key) {
    const dic = this.props.searchToolLangDic;
    return dic[key] || key;
  }

  async _fetchData(state, needMakeItemsUndefined = true) {
    if (this.is_fetching_ === true) {
      return;
    }
    this.is_fetching_ = true;

    if (needMakeItemsUndefined) this.setState({ items: undefined });

    await this.props.onFetchData();

    this.mount_checker_.throwWhenUnmounted();

    let {
      page,
      limit_type,
      sort_type,
      search_key,
      search_value,
      ...rest_query
    } = this._parseQuery();
    search_value = search_value.trim();
    const additional_query = {};
    for (const key in rest_query) {
      if (key.substr(0, 11) !== "additional.") continue;

      additional_query[key] = rest_query[key];
    }
    let page_count = 1;

    // apply lang dic
    const sorttype_ordermaker_map = {};
    const limittype_limit_map = {};
    const searchkey_wheremaker_map = {};
    Object.keys(this.props.sorttypeOrdermakerMap).forEach(
      (k) =>
        (sorttype_ordermaker_map[this._getFromLangDic(k)] =
          this.props.sorttypeOrdermakerMap[k])
    );
    Object.keys(this.props.limittypeLimitMap).forEach(
      (k) =>
        (limittype_limit_map[this._getFromLangDic(k)] =
          this.props.limittypeLimitMap[k])
    );
    Object.keys(this.props.searchkeyWheremakerMap).forEach(
      (k) =>
        (searchkey_wheremaker_map[this._getFromLangDic(k)] =
          this.props.searchkeyWheremakerMap[k])
    );

    const sort_type_data = Object.assign({}, state.sort_type_data, {
      selected_item: sort_type,
    });
    if (!sorttype_ordermaker_map[sort_type])
      sort_type_data.selected_item = this.props.querykeyDefaultMap.sort_type;

    const limit_type_data = Object.assign({}, state.limit_type_data, {
      selected_item: limit_type,
    });
    if (!limittype_limit_map[limit_type])
      limit_type_data.selected_item = this.props.querykeyDefaultMap.limit_type;
    const limit = limittype_limit_map[limit_type];

    const searchable_fields_data = Object.assign(
      {},
      state.searchable_fields_data,
      { selected_item: search_key }
    );
    let search_keyword = search_value;
    if (!searchkey_wheremaker_map[search_key]) {
      searchable_fields_data.selected_item =
        this.props.querykeyDefaultMap.search_key;
      search_keyword = "";
    }

    const additional_options_type_data_map = Object.assign(
      {},
      state.additional_options_type_data_map
    );
    for (const key in this.props.additionalOptionsMakerDataMap) {
      const data = (additional_options_type_data_map[key].selected_item =
        additional_query[`additional.${key}`] ||
        this.props.querykeyDefaultMap.additional[key]);
    }

    let items = [];
    try {
      // make where object
      const where = {};
      const include = [];
      if (search_keyword !== "")
        searchkey_wheremaker_map[search_key](where, include, search_keyword);

      // make order array
      const order = [];
      sorttype_ordermaker_map[sort_type](order);

      // make options with additionals
      const basic_options = { where, include };
      for (const key in additional_query) {
        const additional_key = key.substr(11);
        const maker_data =
          this.props.additionalOptionsMakerDataMap[additional_key];
        if (maker_data === undefined) continue;

        const value = additional_query[key];
        maker_data.maker(basic_options, value);
      }

      // fetch count
      const { params: count_params, options: count_options } =
        this.props.reviseFetchParams(
          {
            ...basic_options,
            distinct: true,
          },
          {}
        );
      const count = (await this.props.fetchFunc(count_params, true)).items[0];
      page_count = Math.max(1, Math.ceil(count / limit));
      page = Math.max(1, Math.min(page_count, page));

      // fetch data
      const { params, options } = this.props.reviseFetchParams(
        {
          ...basic_options,
          offset: limit * (page - 1),
          limit,
          order,
        },
        {}
      );
      items = (await this.props.fetchFunc(params)).items;
      console.log(items);
      await this.props.postFetch(items);
    } catch (e) {
      console.log(e);
    }

    this.mount_checker_.throwWhenUnmounted();

    page = Math.max(1, Math.min(page_count, page));

    const query = {
      page,
      limit_type,
      sort_type,
      search_key,
      search_value,
      ...additional_query,
    };
    this.props.history.replace(
      this.props.path + "?" + query_string.stringify(query)
    );

    this.setState({
      items,
      page_count,
      limit_type_data,
      sort_type_data,
      additional_options_type_data_map,
      searchable_fields_data,
      search_keyword,

      page,
      limit_type,
      sort_type,
      search_key,
      search_value,
      ...additional_query,
    });

    this.is_fetching_ = false;
  }
}

TableView.defaultProps = {
  path: "/administrator",
  idExpForCount: "id",
  adminTemplateEngine: new AdminTemplateEngine({}),
  fetchFunc: () => {
    return { items: [] };
  },
  reviseFetchParams: (params, options) => ({ params, options }),
  onFetchData: () => {},
  postFetch: (items) => {},
  onAdd: undefined, // () => {},
  editPath: undefined,
  headMaker: (ri, row) => {
    return [];
  },
  tailMaker: (ri, row) => {
    return [];
  },
  isCreatable: true,

  orderTitle: "order",
  limitTitle: "limit",
  searchTitle: "search",
  applyTitle: "apply",
  addTitle: "Add",
  editTitle: "edit",
  downloadCsvTitle: "Download CSV",

  sorttypeOrdermakerMap: {
    "id 오름차순": (order) => order.push(["id", "ASC"]),
    "id 내림차순": (order) => order.push(["id", "DESC"]),
    "name 오름차순": (order) => order.push(["name", "ASC"]),
    "name 내림차순": (order) => order.push(["name", "DESC"]),
    "role 오름차순": (order) => order.push(["role", "ASC"]),
    "role 내림차순": (order) => order.push(["role", "DESC"]),
  },

  limittypeLimitMap: {
    "2개씩": 2, // for test
    "10개씩": 10,
    "30개씩": 30,
    "50개씩": 50,
    "100개씩": 100,
  },

  additionalOptionsMakerDataMap: {
    // '상태': {
    // 	items: ['전체', 'OPEN', 'CLOSED'],
    //   maker: (options, value) => {},
    // },
  },

  searchkeyWheremakerMap: {
    id: (where, include, keyword) => (where["id"] = keyword),
    login_id: (where, include, keyword) =>
      (where["login_id"] = { $like: `%${keyword}%` }),
    name: (where, include, keyword) =>
      (where["name"] = { $like: `%${keyword}%` }),
    role: (where, include, keyword) =>
      (where["role"] = { $like: `%${keyword}%` }),
  },

  querykeyDefaultMap: {
    dirty_no: 0,
    page: 1,
    limit_type: "2개씩",
    // limit_type  : '30개씩',
    sort_type: "id 오름차순",
    search_key: "name",
    search_value: "",
    additional: {},
  },

  searchToolLangDic: {},
  beforeAddBtn: null,

  isCsvDownloadable: false,
  reviseDataOfCsv: (items, fields, rows) => {},
  csvFilename: "file.csv",
};

export default withRouter(TableView);
