import React, { Component } from 'react';
import $                    from 'jquery';

import AdminTemplateEngine from '../../util/admin_template_engine';
import common              from '../../util/common';
import MountChecker        from '../../util/mount_checker';
import server_api          from '../../util/server_api';
import TableEdit           from '../common/TableEdit';
import global_vars_data   from './global_vars_data_format';


class GlobalVarsEdit extends Component {
	constructor(props) {
		super(props);
		
		this.ate_ = new AdminTemplateEngine(
      global_vars_data.data_format,
      {},
			global_vars_data.lang_dic,
		);

		this.mount_checker_ = new MountChecker();
	}

	componentDidMount () {
		this.mount_checker_.onComponentDidMount();
	}

	componentWillUnmount () {
		this.mount_checker_.onComponentWillUnmount();
	}

	render () {
		common.goBackIfUnavailableRole(['SUPERUSER']);
		
		return (
			<TableEdit
				redirectPath="/global-vars"
				adminTemplateEngine={this.ate_}
        fetchFunc  ={server_api.fetchGlobalVars}
				updateFunc ={server_api.updateGlobalVar}
				needDel={false}
				addTitle    ="추가"
				confirmTitle="확정"
				deleteTitle ="삭제"
			/>
		);
	}

}

export default GlobalVarsEdit;