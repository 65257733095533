import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import $ from "jquery";
import query_string from "query-string";

import LoadingText from "../../snippets/LoadingText";
import NoValueText from "../../snippets/NoValueText";
import YesNoModal from "../../snippets/YesNoModal";
import AdminTemplateEngine from "../../util/admin_template_engine";
import MountChecker from "../../util/mount_checker";
import { allSettled } from "q";

class TableEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      edit_type: "EDIT", // EDIT | ADD
      datum: undefined,
      err_list: [],

      redirect_url: "",
    };

    this.mount_checker_ = new MountChecker();
  }

  componentDidMount() {
    this.mount_checker_.onComponentDidMount();
    this._fetchData();
  }

  componentWillUnmount() {
    this.mount_checker_.onComponentWillUnmount();
  }

  render() {
    if (this.state.redirect_url.length > 0)
      return <Redirect to={this.state.redirect_url} />;

    const datum = this.state.datum;

    if (datum === undefined)
      return (
        <div className="mt-5 d-flex justify-content-center">
          <LoadingText />
        </div>
      );

    if (datum === null)
      return (
        <div className="mt-5 d-flex justify-content-center">
          <NoValueText />
        </div>
      );

    const err_list = this.state.err_list;
    const key_errcheck_map = {};
    err_list.map((e) => (key_errcheck_map[e[0]] = true));

    const forms = [];
    const key_jsx_map = this.props.adminTemplateEngine.makeKeyJsxMapForEdit(
      datum,
      () => this.setState({})
    );
    for (const key in key_jsx_map) {
      const jsx = key_jsx_map[key];
      forms.push(
        <div
          key={key}
          className={
            "p-2 m-1 d-flex align-items-center " +
            (key_errcheck_map[key] === true ? "bg-danger text-white" : "")
          }
        >
          <div className="mr-3 text-right" style={{ flex: "0 0 11rem" }}>
            {this.props.adminTemplateEngine.withLangDic(key)}
          </div>
          <div className="flex-grow-1">{jsx}</div>
        </div>
      );
    }

    const yes_button_title =
      this.props.yesButtonTitle !== undefined
        ? this.props.yesButtonTitle
        : this.state.edit_type === "ADD"
        ? this.props.addTitle
        : this.props.confirmTitle;

    return (
      <div className="d-flex flex-column align-items-center p-0 m-0 px-0 mb-5">
        <div className="w-100 p-0 m-0">
          <div className="row justify-content-center mx-0">
            <div className="col-12 col-md-9 col-lg-6 p-0">{forms}</div>
          </div>

          {/* err list */}
          <div
            className={
              "mt-4 w-100 alert alert-danger m-0 " +
              (err_list.length > 0 ? "visible" : "invisible")
            }
          >
            <div className="d-flex justify-content-center">
              <div>
                {err_list.map(([key, msg], idx) => (
                  <div key={idx} className="text-center">
                    <small>
                      {this.props.adminTemplateEngine.withLangDic(key) + msg}
                    </small>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* buttons */}
          <div className="d-flex justify-content-center mt-3 mx-0">
            <div className="" style={{ flex: "0 0 11rem" }}></div>
            <button
              type="button"
              className="btn btn-sm btn-dark px-5 mr-2 mb-2"
              onClick={() => this._onConfirm()}
            >
              {yes_button_title}
            </button>
            <button
              type="button"
              className={
                "btn btn-sm btn-danger px-3 mr-2 mb-2" +
                (this.state.edit_type === "ADD" ? " d-none" : "") +
                (this.props.needDel ? "" : " d-none")
              }
              onClick={() => {
                $("#delete_modal").modal({
                  backdrop: "static",
                  keyboard: false,
                });
              }}
            >
              {this.props.deleteTitle}
            </button>
            {/* <button type="button" onClick={()=>this._test()}
							className="btn btn-sm btn-dark mb-2 ">test</button> */}
          </div>
        </div>
        <YesNoModal
          id="confirm_modal"
          message={`Do you want to "${yes_button_title}" ?`}
          onYes={() => this._onYes()}
          onYesHidden={() => this._redirectToList()}
        />
        <YesNoModal
          id="delete_modal"
          message={"Do you want to delete this?"}
          onYes={() => this._onDelete()}
          onYesHidden={() => this._redirectToList()}
        />
      </div>
    );
  }

  _onConfirm() {
    const err_list = this.props.adminTemplateEngine.validateAll(
      this.state.datum
    );
    this.setState({
      err_list,
    });
    if (err_list.length > 0) return;

    $("#confirm_modal").modal({
      backdrop: "static",
      keyboard: false,
    });
  }

  async _onYes() {
    try {
      await this.props.onYes(this.state.datum, this.state.edit_type);

      let datum = null;
      if (this.state.edit_type === "ADD") datum = await this._createItem();
      else if (this.state.edit_type === "EDIT")
        datum = await this._updateItem();

      await this.props.afterYes(datum);
      return true;
    } catch (e) {
      console.log(e);
      const re = /same (\w+) exists/;
      if (e.result) {
        if (e.result.message === undefined)
          return this.setState({
            err_list: [["", JSON.stringify(e.result, null, 2)]],
          });

        const res = e.result.message.match(re);
        if (res !== null)
          return this.setState({ err_list: [[res[1], e.result.message]] });
        else return this.setState({ err_list: [["", e.result.message]] });
      }

      this.setState({ err_list: [["", e.toString()]] });
      return false;
    }
  }

  async _createItem() {
    let datum = Object.assign({}, this.state.datum);

    for (const key in datum) {
      if (typeof datum[key] === "string" && datum[key].trim() === "")
        delete datum[key];
    }

    datum = await this.props.reviseDatum(datum);
    const { params, options } = await this.props.reviseCreateParams(datum, {});
    const res = await this.props.createFunc(params, options);
    if (res.items && res.items.length > 0) params.id = res.items[0].id;

    return params;
  }

  async _updateItem() {
    let { id, ...datum } = Object.assign({}, this.state.datum);
    if (datum.password === "") delete datum.password;

    if (datum.is_lock && datum.currency && datum.amount) {
      const _amount = +datum.amount * (+datum.lock_percent / 100);

      datum.lock_amount = _amount.toFixed(4);
      datum.lock_transfer_amount = "0";
    }
    datum = await this.props.reviseDatum(datum);
    const { params, options } = await this.props.reviseUpdateParams(datum, {});
    const res = await this.props.updateFunc(id, params, options);
    params.id = id;
    return params;
  }

  async _onDelete() {
    if (this.state.edit_type !== "EDIT") return false;

    try {
      await this.props.deleteFunc(this.state.datum.id);
      return true;
    } catch (e) {
      console.log(e);
      if (e.result && e.result.message) {
        if (
          e.result.message.split(":", 1)[0] ===
          "SequelizeForeignKeyConstraintError"
        ) {
          this.setState({
            err_list: [
              [
                "데이터 오류: ",
                "다른 데이터에서 참조하고 있어서, 삭제할 수 없습니다.",
              ],
            ],
          });
          return false;
        }

        const re = /same (\w+) exists/;
        const res = e.result.message.match(re);
        if (res !== null) {
          this.setState({ err_list: [[res[1], e.result.message]] });
          return false;
        }
      }

      if (e.title && e.content) {
        this.setState({ err_list: [[`${e.title}: `, e.content]] });
        return false;
      }

      if (typeof e === "object") {
        this.setState({
          err_list: [["unknown: ", JSON.stringify(e, null, 2)]],
        });
        return false;
      }

      this.setState({ err_list: [["unknown: ", e.toString()]] });
      return false;
    }
  }

  async _redirectToList() {
    if (this.props.redirectPath === window.location.pathname)
      return this._fetchData();

    this.setState({ redirect_url: this.props.redirectPath });
  }

  async _fetchData() {
    await this.props.onFetchData();

    let id = this.props.rowId;
    if (id === undefined) {
      const query = query_string.parse(window.location.search);
      id = query.id;
    }

    let data = null;
    try {
      // fetch
      if (id !== undefined) {
        // edit
        const { params, options } = await this.props.reviseFetchParams(
          { where: { id }, include: [] },
          {}
        );
        data = (await this.props.fetchFunc(params, options)).items;
      } // add
      else data = [];

      await this.props.postFetch(data);
    } catch (e) {
      this.mount_checker_.throwWhenUnmounted();
      console.log(e);
      return this.setState({ datum: null });
    }

    this.mount_checker_.throwWhenUnmounted();

    if (id === undefined) {
      // for add
      const datum = this.props.adminTemplateEngine.makeDefaultDatum();
      datum.id = "";
      return this.setState({ edit_type: "ADD", datum });
    }

    if (data.length <= 0) return this.setState({ datum: null });

    const datum = data[0];
    datum.password = "";
    return this.setState({ edit_type: "EDIT", datum });
  }

  _test() {
    console.log(this.state.datum);
    console.log(this.props.adminTemplateEngine.validateAll(this.state.datum));
  }
}

TableEdit.defaultProps = {
  redirectPath: "/administrator",
  adminTemplateEngine: new AdminTemplateEngine({}),
  yesButtonTitle: undefined,
  fetchFunc: () => {},
  createFunc: () => {},
  updateFunc: () => {},
  deleteFunc: () => {},
  onFetchData: () => {},
  postFetch: (data) => {},
  onYes: (datum, edit_type) => {},
  afterYes: (datum) => {},
  reviseDatum: (datum) => datum,
  reviseFetchParams: (params, options) => ({ params, options }),
  reviseCreateParams: (params, options) => ({ params, options }),
  reviseUpdateParams: (params, options) => ({ params, options }),
  rowId: undefined,
  needDel: true,
  addTitle: "Add",
  confirmTitle: "Confirm",
  deleteTitle: "Delete",
};

export default TableEdit;
