import React, { Component } from "react";

import AdminTemplateEngine from "../../util/admin_template_engine";
import common from "../../util/common";
import MountChecker from "../../util/mount_checker";
import server_api from "../../util/server_api";
import TableView from "../common/TableView";
import bank_data from "./coin_exchange_data_format";

class CoinExchange extends Component {
  constructor(props) {
    super(props);

    this.state = {
      brands: [],
    };

    this.ate_ = new AdminTemplateEngine(
      bank_data.data_format,
      {},
      bank_data.lang_dic
    );

    this.mount_checker_ = new MountChecker();
  }

  componentDidMount() {
    this.mount_checker_.onComponentDidMount();
  }

  componentWillUnmount() {
    this.mount_checker_.onComponentWillUnmount();
  }

  render() {
    return (
      <div style={{ minHeight: "60rem" }}>
        <TableView
          path="/coin-exchange"
          idExpForCount="CoinExchange.id"
          adminTemplateEngine={this.ate_}
          isCreatable={false}
          fetchFunc={server_api.fetchCoinExchanges}
          sorttypeOrdermakerMap={{
            "id 내림차순": (order) => order.push(["id", "DESC"]),
            "id 오름차순": (order) => order.push(["id", "ASC"]),
          }}
          limittypeLimitMap={{
            "10개씩": 10,
            "30개씩": 30,
            "50개씩": 50,
            "100개씩": 100,
          }}
          searchkeyWheremakerMap={{
            id: (where, include, keyword) => (where["id"] = keyword),
            상태: (where, include, keyword) =>
              (where["state"] = { $like: `%${keyword}%` }),
          }}
          querykeyDefaultMap={{
            page: 1,
            limit_type: "10개씩",
            sort_type: "id 내림차순",
            search_key: "",
            search_value: "",
          }}
          orderTitle="정렬"
          limitTitle="제한"
          searchTitle="검색"
          applyTitle="적용"
          addTitle="추가"
          editTitle="수정"
        />
      </div>
    );
  }
}

export default CoinExchange;
