import AdminTemplateEngine from "../../util/admin_template_engine";
import string_util from "../../util/string_util";

export default {
  data_format: {
    id      : { type: "int", is_readonly: true, },
    created : {
      type: 'view',
      view: (datum, user_data) => '',
      present : (datum, user_data) => {
        const dt = new Date(datum.createdAt);
        return string_util.makeYmdHms(dt);
      },
      onChange: (datum, value, user_data)=>{},
    },
    from_user_id : { type: 'int', is_hidden: true, },
    from_user    : {
      type: 'view',
      view: (datum, user_data) => '',
      present : (datum, user_data) => {
        if (!datum.from_user)
          return `[지갑] ${datum.from_eos_account}`;
        return `${datum.from_user.id}: ${datum.from_user.login_id}`;
      },
      onChange: (datum, value, user_data)=>{},
    },
    to_user_id : { type: 'int', is_hidden: true, },
    to_user    : {
      type: 'view',
      view: (datum, user_data) => '',
      present : (datum, user_data) => {
        if (!datum.to_user)
          return `[지갑] ${datum.to_eos_account}`;
        return `${datum.to_user.id}: ${datum.to_user.login_id}`;
      },
      onChange: (datum, value, user_data)=>{},
    },
    token_symbol: { type: 'string', is_hidden: true, },
    amount      : {
      type: 'view',
      view: (datum, user_data) => '',
      present : (datum, user_data) => {
        return `${datum.amount} ${datum.token_symbol}`;
      },
      onChange: (datum, value, user_data)=>{},
    },
    status: { type: 'string', },
    log   : { type: 'text', },
  },

  lang_dic: {
    created: '작성일',
    exchange_type: '환전 방식',
    from_user: '보내는이',
    to_user: '받는이',
    amount: '코인량',
    status: '상태',
    log: '메모',
  },
}
