import $ from 'jquery';
import React, { Component } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { ToastContainer, toast } from 'react-toastify';

import YesNoModal               from '../../../snippets/YesNoModal';
import AdminTemplateEngine      from '../../../util/admin_template_engine';
import common                   from '../../../util/common';
import MountChecker             from '../../../util/mount_checker';
import server_api               from '../../../util/server_api';
import string_util              from '../../../util/string_util';
import TableView                from '../../common/TableView';
import calc_token_to_money_data from './calc_token_to_money_data_format';




class CalcTokenToMoney extends Component {
	constructor(props) {
    super(props);
    
    this.state = {
      is_loading: false,
      dirty_no: 0,
      detail_body: null,
    };

    this.ate_ = new AdminTemplateEngine(calc_token_to_money_data.data_format, {}, calc_token_to_money_data.lang_dic);
    
    this.mount_checker_ = new MountChecker();

    this.items_ = [];
    this.id_item_map_ = {};
    this.on_post_fetch_ = ()=>{};
	}
  
  componentDidMount () {
    this.mount_checker_.onComponentDidMount();
  }

  componentWillUnmount () {
    this.mount_checker_.onComponentWillUnmount();
  }
	
	render () {

		return (
      <LoadingOverlay
        active={this.state.is_loading}
        spinner
        text='Loading ...'
      >
        <div style={{minHeight: '60rem'}}>
          <TableView
            isCreatable={false}
            dirty_no={this.state.dirty_no}
            path="/calculate/token-to-money"
            idExpForCount="TokenToMoney.id"
            editPath=""
            adminTemplateEngine={this.ate_}
            tailMaker={(ri, row)=>this._tailMaker(ri, row)}
            fetchFunc={server_api.fetchTokenToMoneys}
            reviseFetchParams={(params, options)=>this._onReviseFetchParams(params, options)}
            postFetch={(items)=>this._postFetch(items)}
            sorttypeOrdermakerMap={{
              'id 오름차순'   : (order) => order.push(['id', 'ASC' ]),
              'id 내림차순'  : (order) => order.push(['id', 'DESC']),
            }}
            limittypeLimitMap={{
              '10개씩' :  10,
              '30개씩' :  30,
              '50개씩' :  50,
              '100개씩': 100,
            }}
            searchkeyWheremakerMap={{
              'id'           : (where, include, keyword) => where['id'     ] = keyword,
              '유저 아이디': (where, include, keyword) => {
                include.push({
                  required: true,
                  association: 'user',
                  where: { login_id: keyword, },
                });
              },
              '지점 아이디': (where, include, keyword) => {
                include.push({
                  required: true,
                  association: 'exchanger',
                  where: { login_id: keyword, },
                });
              },
            }}
            querykeyDefaultMap={{
              page        : 1,
              limit_type  : '10개씩',
              sort_type   : 'id 내림차순',
              search_key  : '유저 아이디',
              search_value: '',
            }}
            orderTitle ="정렬"
            limitTitle ="제한"
            searchTitle="검색"
            applyTitle ="적용"
            addTitle   ="추가"
            editTitle  ="수정"
            downloadCsvTitle ="다운로드 CSV"
            isCsvDownloadable={true}
            reviseDataOfCsv  ={(items, fields, rows)=>this._onReviseDataOfCsv(items, fields, rows)}
            csvFilename      ={`화폐환전-${string_util.makeYmdHms(new Date())}.csv`}
          />
        </div>
				<YesNoModal
					id="detail_modal"
          message={''}
          body={this.state.detail_body}
          needNo={false}
          yesStr="Ok"
				/>
      </LoadingOverlay>
		);
  }

  _onReviseDataOfCsv (items, fields, rows) {
    rows.forEach((r) => {
      r[2] = r[2].replace(/\<span.*\>/g, '').replace(/\<\/span\>/g, '').trim();
      r[4] = r[4].replace(/<div>/g, '').split('</div>').map((d)=>d.trim()).filter((d)=>d!=='').join('\n');
      r[6] = r[6].replace(/<div>/g, '').split('</div>').map((d)=>d.trim()).filter((d)=>d!=='').join('\n');
    });
  }
  
	_onReviseFetchParams (params, options) {
    params.where.status = 'OK';
    params.include = params.include.concat([
      { association: 'user'                 , attributes: ['id', 'login_id'], },
      { association: 'user_bankaccount'     , include: ['bank'], },
      { association: 'exchanger'            , attributes: ['id', 'login_id', 'name'], },
      { association: 'exchanger_bankaccount', include: ['bank'], },
    ]);

		return {params, options};
  }

  _tailMaker (ri, row) {
    if (ri === null)
      return [ '상세보기' ];
    
    const id = row[0];
    const item = this.id_item_map_[id];
    if (!item)
      return [''];
    
    const detail_jsx = (
      <button
        className="w-100 btn btn-sm btn-warning"
        onClick={() => {
          const detail_body = this._makeDetailBody(item);
          this.setState({detail_body});
          $('#detail_modal').modal({backdrop: 'static', keyboard: false});
        }}
      >
        <div>상세보기</div>
      </button>
    );

    return [
      detail_jsx,
    ];
  }

  async _postFetch (items) {
    this.items_ = items;
    this.id_item_map_ = {};
    items.forEach((it)=>this.id_item_map_[it.id]=it);
    
    await this.on_post_fetch_();
    this.on_post_fetch_ = ()=>{};
  }
  
  _makeDetailBody (item) {
    const exchangetype_kor_map = {
      BY_BANK_ACCOUNT: '계좌이체',
      BY_OFFLINE_EXCHANGER: '지점방문',
    };

    const currency_symbol = item.token_symbol.replace('IOO', '').substr(0, item.token_symbol.length - 1);
    const ub = item.user_bankaccount;
    const eb = item.exchanger_bankaccount;
    const my_commission_rate = item.commission_rate_for_owner * 100.0;
    const my_commission_amount = parseFloat(item.commission_amount_for_owner);
    const info = {
      id                       : item.id,
      created                  : string_util.makeYmdHms(item.createdAt),
      exchange_type            : exchangetype_kor_map[item.exchange_type],
      user                     : `${item.user.id}: ${item.user.login_id}`,
      user_bank_account        : (!ub)? null: `${ub.id}: [${ub.bank.country_code}] ${ub.bank.name} ${ub.bank_account} ${ub.owner_name}`,
      exchanger_bank_account   : (!eb)? null: `${eb.id}: [${eb.bank.country_code}] ${eb.bank.name} ${eb.bank_account} ${eb.owner_name}`,
      from                     : `${string_util.makeCommaedString(item.token_amount, 4).replace(/\./, ' .')} ${item.token_symbol}`,
      to                       : `${string_util.makeCommaedString(item.money_amount, 4).replace(/\./, ' .')} ${currency_symbol}`,
      total_commission_rate    : `${string_util.removeTails(string_util.makeCommaedString(item.commission_rate*100.0, 3), ['.', '0'])} %`,
      total_commission_amount  : `${string_util.makeCommaedString(item.commission_amount, 4).replace('.', ' .')} ${currency_symbol}`,
      my_commission_rate       : `${string_util.removeTails(string_util.makeCommaedString(my_commission_rate, 3), ['.', '0'])} %`,
      my_commission_amount     : `${string_util.makeCommaedString(my_commission_amount, 4).replace('.', ' .')} ${currency_symbol}`,
    };

    return (
      <div>
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">key</th>
              <th scope="col">value</th>
            </tr>
          </thead>
          <tbody>
            {
              Object.keys(info).map((key) => {
                console.log(key);
                const value = info[key];
                if (!value)
                  return null;
                return (
                  <tr key={key}>
                    <th scope="row">{this.ate_.lang_dic_[key]}</th>
                    <td>{value}</td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    );
  }

}


export default CalcTokenToMoney;