import React, { Component } from "react";
import $ from "jquery";

import AdminTemplateEngine from "../../util/admin_template_engine";
import common from "../../util/common";
import MountChecker from "../../util/mount_checker";
import server_api from "../../util/server_api";
import TableEdit from "../common/TableEdit";
import bank_data from "./account_data_format";

class AccountEdit extends Component {
  constructor(props) {
    super(props);

    this.ate_ = new AdminTemplateEngine(
      bank_data.data_format,
      {},
      bank_data.lang_dic
    );

    this.mount_checker_ = new MountChecker();
  }

  componentDidMount() {
    this.mount_checker_.onComponentDidMount();
  }

  componentWillUnmount() {
    this.mount_checker_.onComponentWillUnmount();
  }

  render() {
    return (
      <TableEdit
        redirectPath="/account"
        adminTemplateEngine={this.ate_}
        fetchFunc={server_api.fetchAccounts}
        updateFunc={server_api.updateAccount}
        needDel={false}
        addTitle="추가"
        confirmTitle="확정"
        deleteTitle="삭제"
      />
    );
  }
}

export default AccountEdit;
