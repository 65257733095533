import React from 'react';

import AdminTemplateEngine from "../../../util/admin_template_engine";
import string_util         from "../../../util/string_util";

export default {
  data_format: {
    id      : { type: "int", is_readonly: true, },
    created : {
      type: 'view',
      view: (datum, user_data) => '',
      present : (datum, user_data) => {
        const dt = new Date(datum.createdAt);
        return string_util.makeYmdHms(dt);
      },
      onChange: (datum, value, user_data)=>{},
    },
    exchange_type: {
      type: 'view',
      view: (datum, user_data) => '',
      present : (datum, user_data) => {
        switch (datum.exchange_type) {
          case 'BY_BANK_ACCOUNT':
            return <span className="badge badge-success">계좌이체</span>
          case 'BY_OFFLINE_EXCHANGER':
            return <span className="badge badge-primary">지점방문</span>
          default:
            return datum.exchange_type;
        }
      },
      onChange: (datum, value, user_data)=>{},
    },
    user_id : { type: 'int', is_hidden: true, },
    user    : {
      type: 'view',
      view: (datum, user_data) => '',
      present : (datum, user_data) => {
        return `${datum.user.id}: ${datum.user.login_id}`;
      },
      onChange: (datum, value, user_data)=>{},
    },
    user_bankaccount_id: { type: 'int', is_hidden: true, },
    user_bank_account   : {
      type: 'view',
      view: (datum, user_data) => '',
      present : (datum, user_data) => {
        const ub = datum.user_bankaccount;
        if (!ub)
          return '';
        return (
          <div>
            <div>{`${ub.id}: [${ub.bank.country_code}] ${ub.bank.name}`}</div>
            <div>{`${ub.bank_account} ${ub.owner_name}`}</div>
          </div>
        );
      },
      onChange: (datum, value, user_data)=>{},
    },
    exchanger_id : { type: 'int', is_hidden: true, },
    exchanger    : {
      type: 'view',
      view: (datum, user_data) => '',
      present : (datum, user_data) => {
        return `${datum.exchanger.id}: ${datum.exchanger.name}`;
      },
      onChange: (datum, value, user_data)=>{},
    },
    exchanger_bankaccount_id: { type: 'int', is_hidden: true, },
    exchanger_bank_account   : {
      type: 'view',
      view: (datum, user_data) => '',
      present : (datum, user_data) => {
        const eb = datum.exchanger_bankaccount;
        if (!eb)
          return '';
        return (
          <div>
            <div>{`${eb.id}: [${eb.bank.country_code}] ${eb.bank.name}`}</div>
            <div>{`${eb.bank_account} ${eb.owner_name}`}</div>
          </div>
        );
      },
      onChange: (datum, value, user_data)=>{},
    },
    from: {
      type: 'view',
      view: (datum, user_data) => '',
      present : (datum, user_data) => {
        return `${string_util.makeCommaedString(datum.token_amount, 4).replace(/\./, ' .')} ${datum.token_symbol}`;
      },
      onChange: (datum, value, user_data)=>{},
    },
    to: {
      type: 'view',
      view: (datum, user_data) => '',
      present : (datum, user_data) => {
        const currency_symbol = datum.token_symbol.replace('IOO', '').substr(0, datum.token_symbol.length - 1);
        return `${string_util.makeCommaedString(datum.money_amount, 4).replace(/\./, ' .')} ${currency_symbol}`;
      },
      onChange: (datum, value, user_data)=>{},
    },
    commission_rate_for_me: {
      type: 'view',
      view: (datum, user_data) => '',
      present : (datum, user_data) => {
        const commission_rate = datum.commission_rate_for_owner * 100.0;
        return `${string_util.removeTails(string_util.makeCommaedString(commission_rate, 3), ['.', '0'])} %`;
      },
      onChange: (datum, value, user_data)=>{},
    },
    commission_amount_for_me: {
      type: 'view',
      view: (datum, user_data) => '',
      present : (datum, user_data) => {
        const commission_amount = parseFloat(datum.commission_amount_for_owner);
        const currency_symbol = datum.token_symbol.replace('IOO', '').substr(0, datum.token_symbol.length - 1);
        return `${string_util.makeCommaedString(commission_amount, 4).replace('.', ' .')} ${currency_symbol}`;
      },
      onChange: (datum, value, user_data)=>{},
    },
  },

  lang_dic: {
    id: 'id',
    created: '생성일',
    exchange_type: '환전',
    user: '유저',
    user_bank_account: '유저 계좌',
    exchanger: '지점',
    exchanger_bank_account: '지점 계좌',
    from: '코인량',
    to: '화폐량',
    commission_rate_for_me: '수수료율',
    commission_amount_for_me: '수수료',

    total_commission_rate: '총 수수료율',
    total_commission_amount: '총 수수료',
    my_commission_rate: '내 수수료율',
    my_commission_amount: '내 수수료',
  },

}
