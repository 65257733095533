import query_string from "query-string";

import common from "./common";

const api_server_address =
  process.env.NODE_ENV === "development"
    ? "http://localhost:4001/dev"
    : "https://d717mdfbai.execute-api.ap-northeast-1.amazonaws.com/prod";
const api_server_address2 =
  process.env.NODE_ENV === "development"
    ? "http://localhost:4002/dev"
    : "https://jc03q7rm8d.execute-api.ap-northeast-1.amazonaws.com/prod";
const api_server_address3 =
  process.env.NODE_ENV === "development"
    ? "http://localhost:4003/dev"
    : "https://qgmmrlyawl.execute-api.ap-northeast-1.amazonaws.com/prod";

async function requestWithBody(server_url, path, method, params = {}) {
  let url = server_url + "/" + path;

  const res = await fetch(url, {
    method,
    body: JSON.stringify(params),
    headers: { "x-api-key": common.token },
  });
  // console.log(res)

  const res_json = await res.json();
  // console.log(res_json);
  if (res.status === 401) {
    console.log(res_json);
    common.logout();
  } else if (Math.floor(res.status / 100) !== 2) {
    throw {
      status: res.status,
      result: res_json,
    };
  }

  return res_json;
}

async function requestWithQuery(server_url, path, method, params = {}) {
  let url = server_url + "/" + path;

  if (Object.keys(params).length > 0)
    url += "?" + query_string.stringify(params);

  const res = await fetch(url, {
    method,
    headers: { "x-api-key": common.token },
  });

  const res_json = await res.json();
  if (res.status === 401) {
    common.logout();
    window.location.href = "/login";
  } else if (Math.floor(res.status / 100) !== 2) {
    throw {
      status: res.status,
      result: res_json,
    };
  }

  return res_json;
}

async function _fetchAll(server_url, path, options) {
  options.offset = 0;
  options.limit = 1000;

  const result = {
    items: [],
  };
  while (true) {
    const res = await requestWithQuery(server_url, path, "GET", {
      options: JSON.stringify(options),
    });
    if (res.items === undefined || res.items.length <= 0) break;

    options.offset += res.items.length;
    result.items = result.items.concat(res.items);
  }
  return result;
}

function _crudMaker(server_url, name, uppercased = undefined) {
  if (uppercased === undefined)
    uppercased = name[0].toUpperCase() + name.slice(1);
  const path = "api/" + name + "s";

  const result = {};
  result["fetch" + uppercased + "s"] = async (options = {}, need_count) => {
    const res = await requestWithQuery(server_url, path, "GET", {
      options: JSON.stringify(options),
      need_count,
    });
    console.log(res);
    return res;
  };

  result["fetchAll" + uppercased + "s"] = async (options = {}) => {
    const res = await _fetchAll(server_url, path, options);
    return res;
  };

  result["create" + uppercased] = async (datum, options = {}) => {
    return await requestWithBody(server_url, path, "POST", {
      params: datum,
      options,
    });
  };

  result["update" + uppercased] = async (id, datum, options = {}) => {
    console.log(datum);
    console.log("DATAU!!!");
    return await requestWithBody(server_url, path + "/" + id, "PUT", {
      params: datum,
      options,
    });
  };

  result["delete" + uppercased] = async (id) => {
    return await requestWithQuery(server_url, path + "/" + id, "DELETE", {});
  };

  return result;
}

export default {
  api_server_address,
  api_server_address2,
  api_server_address3,

  ..._crudMaker(api_server_address, "administrator"),
  ..._crudMaker(api_server_address, "bank"),
  ..._crudMaker(api_server_address, "board"),
  ..._crudMaker(api_server_address, "exchanger"),
  ..._crudMaker(api_server_address, "user"),
  ..._crudMaker(api_server_address, "token"),
  ..._crudMaker(api_server_address, "transfer"),
  ..._crudMaker(api_server_address, "account"),
  ..._crudMaker(api_server_address, "coin-exchange", "CoinExchange"),
  ..._crudMaker(api_server_address, "board-post", "BoardPost"),
  ..._crudMaker(
    api_server_address,
    "exchanger-bankaccount",
    "ExchangerBankaccount"
  ),
  ..._crudMaker(api_server_address, "global-var", "GlobalVar"),
  ..._crudMaker(api_server_address, "money-to-token", "MoneyToToken"),
  ..._crudMaker(api_server_address, "token-to-money", "TokenToMoney"),
  ..._crudMaker(api_server_address, "token-to-token", "TokenToToken"),
  // ..._crudMaker(api_server_address, 'tag'          ),
  // ..._crudMaker(api_server_address, 'warning'      ),
  // ..._crudMaker(api_server_address, 'brand-monthlygoal'               , 'Brand_MonthlyGoal'               ),
  // ..._crudMaker(api_server_address, 'item-has-resource'               , 'ItemHasResource'                 ),

  requestWithQuery,
  requestWithBody,

  // async makeS3Credential (hash, type) {
  //   return await requestWithBody(api_server_address2, 'api/resources/s3-credential', 'POST', {hash, type});
  // },

  async registerExchanger(params) {
    return await requestWithBody(
      api_server_address2,
      `api/exchangers/register`,
      "POST",
      params
    );
  },

  async registerUser(params) {
    return await requestWithBody(
      api_server_address2,
      `api/users/register`,
      "POST",
      params
    );
  },

  async transferToken(
    from_account,
    from_key,
    to_account,
    amount_str,
    token_symbol,
    memo
  ) {
    return await requestWithBody(
      "https://9xbum9cye7.execute-api.ap-southeast-1.amazonaws.com/prod",
      "api/eos/transfer",
      "POST",
      {
        from_account,
        from_key,
        to_account,
        amount_str,
        token_symbol,
        memo,
      }
    );
  },

  async fetchEosAccountBalance(eos_account) {
    return await requestWithQuery(
      api_server_address3,
      "api/hmx-utils/eos-account/balance",
      "GET",
      {
        eos_account,
      }
    );
  },

  async writeBoardPostContent(post_id, content) {
    return await requestWithBody(
      api_server_address2,
      `api/board-posts/${post_id}/contents`,
      "PUT",
      {
        contents: [
          {
            content_type: "TEXT",
            content,
          },
        ],
      }
    );
  },
};
