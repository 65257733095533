import AdminTemplateEngine from "../../util/admin_template_engine";
import s3_util from "../../util/s3_util";
import string_util from "../../util/string_util";

export default {
  data_format: {
    id: { type: "int", is_readonly: true },
    login_id: {
      type: "string",
      validate: AdminTemplateEngine.validateNotEmpty,
    },
    password: {
      type: "password",
      is_hidden_in_list: true,
      deafultValue: "password",
    },
    // eos_account   : { type: 'string'  , is_readonly: true, },
    // eos_active_pub: { type: 'string'  , is_readonly: true, },
    email: { type: "string", validate: AdminTemplateEngine.validateEmail },
    created: {
      type: "view",
      view: (datum, user_data) => "",
      present: (datum, user_data) => {
        const dt = new Date(datum.createdAt);
        return string_util.makeYmdHms(dt);
      },
      onChange: (datum, value, user_data) => {},
    },
  },

  lang_dic: {
    login_id: "로그인 아이디",
    password: "패스워드",
    eos_account: "eos 계정",
    eos_active_pub: "eos active pub",
    email: "이메일",
    created: "가입일",
  },
};
