import React, { Component } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { ToastContainer, toast } from 'react-toastify';

import AdminTemplateEngine from '../../util/admin_template_engine';
import common              from '../../util/common';
import MountChecker        from '../../util/mount_checker';
import server_api          from '../../util/server_api';
import TableView           from '../common/TableView';
import transfer_data       from './transfer_data_format';


class Transfer extends Component {
	constructor(props) {
    super(props);
    
    this.state = {
      is_loading: false,
      dirty_no: 0,
    };

    this.ate_ = new AdminTemplateEngine(transfer_data.data_format, {}, transfer_data.lang_dic);
    
    this.mount_checker_ = new MountChecker();
	}
  
  componentDidMount () {
    this.mount_checker_.onComponentDidMount();
  }

  componentWillUnmount () {
    this.mount_checker_.onComponentWillUnmount();
  }
	
	render () {

		return (
      <LoadingOverlay
        active={this.state.is_loading}
        spinner
        text='Loading ...'
      >
        <div style={{minHeight: '60rem'}}>
          <TableView
            isCreatable={false}
            dirty_no={this.state.dirty_no}
            path="/transfer"
            idExpForCount="Transfer.id"
            editPath=""
            adminTemplateEngine={this.ate_}
            fetchFunc={server_api.fetchTransfers}
            reviseFetchParams={(params, options)=>this._onReviseFetchParams(params, options)}
            sorttypeOrdermakerMap={{
              'id 오름차순'   : (order) => order.push(['id', 'ASC' ]),
              'id 내림차순'  : (order) => order.push(['id', 'DESC']),
            }}
            limittypeLimitMap={{
              '10개씩' :  10,
              '30개씩' :  30,
              '50개씩' :  50,
              '100개씩': 100,
            }}
            searchkeyWheremakerMap={{
              'id'           : (where, include, keyword) => where['id'     ] = keyword,
              '보내는이 [아이디]': (where, include, keyword) => {
                include.push({
                  required: true,
                  association: 'from_user',
                  where: { login_id: keyword, },
                });
              },
              '보내는이 [지갑]': (where, include, keyword) => {
                where.from_eos_account = keyword;
              },
              '받는이 [아이디]': (where, include, keyword) => {
                include.push({
                  required: true,
                  association: 'to_user',
                  where: { login_id: keyword, },
                });
              },
              '받는이 [지갑]': (where, include, keyword) => {
                where.to_eos_account = keyword;
              },
            }}
            querykeyDefaultMap={{
              page        : 1,
              limit_type  : '10개씩',
              sort_type   : 'id 내림차순',
              search_key  : '보내는이 [아이디]',
              search_value: '',
            }}
            orderTitle ="정렬"
            limitTitle ="제한"
            searchTitle="검색"
            applyTitle ="적용"
            addTitle   ="추가"
            editTitle  ="수정"
          />
        </div>
      </LoadingOverlay>
		);
  }
  
	_onReviseFetchParams (params, options) {
    params.include = params.include.concat([
      { association: 'from_user', attributes: ['id', 'login_id'], },
      { association: 'to_user'  , attributes: ['id', 'login_id'], },
    ]);

		return {params, options};
  }
}


export default Transfer;