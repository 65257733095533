import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import './custom-datepicker.css';


class CustomDatePicker extends Component {
  render () {
    return (
      <DatePicker
        dateFormat={this.props.dateFormat}
        customInput={<_CustomInput className={this.props.className}/>}
        selected={this.props.selected}
        onChange={(dt, ev)=>this.props.onChange(dt, ev)}
        minDate   ={this.props.minDate   }
        filterDate={this.props.filterDate}
        readOnly={this.props.readOnly}
      />
    );
  }
}

class _CustomInput extends Component {
  render () {
    return (
      <input
        type="text"
        className={'form-control ' + this.props.className}
        value={this.props.value}
        onChange={this.props.onChange}
        onClick={this.props.onClick}
        readOnly={this.props.readOnly}
      />
    );
  }
}


CustomDatePicker.defaultProps = {
  className: '',
  dateFormat: "yyyy-MM-dd",
  selected: undefined,
  onChange: undefined,
  minDate   : undefined,
  filterDate: undefined,
  showLimit: 7,
  data: {
    current: 1,
  },
  readOnly: undefined,
};

export default CustomDatePicker;

