import React, { Component } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import common from "./util/common.js";

import TopMenu from "./part/TopMenu";
import Administrator from "./views/administrator/Administrator";
import AdministratorEdit from "./views/administrator/AdministratorEdit";
import Bank from "./views/bank/Bank";
import BankEdit from "./views/bank/BankEdit";
import CalcTokenToMoney from "./views/calculate/token_to_money/CalcTokenToMoney";
import CalcTokenToToken from "./views/calculate/token_to_token/CalcTokenToToken";
import Exchanger from "./views/exchanger/Exchanger";
import ExchangerEdit from "./views/exchanger/ExchangerEdit";
import Faq from "./views/faq/Faq";
import FaqEdit from "./views/faq/FaqEdit";
import GlobalVars from "./views/global_vars/GlobalVars";
import GlobalVarsEdit from "./views/global_vars/GlobalVarsEdit";
import Login from "./views/login/Login";
import MoneyToToken from "./views/money_to_token/MoneyToToken";
import Notice from "./views/notice/Notice";
import NoticeEdit from "./views/notice/NoticeEdit";
import Statistics from "./views/statistics/Statistics";
import SuperuserTransfer from "./views/superuser_transfer/SuperuserTransfer";
import TokenToMoney from "./views/token_to_money/TokenToMoney";
import TokenToToken from "./views/token_to_token/TokenToToken";
import Transfer from "./views/transfer/Transfer";
import User from "./views/user/User";
import UserEdit from "./views/user/UserEdit";
import Account from "./views/account/Account";
import AccountEdit from "./views/account/AccountEdit";

import CoinExchange from "./views/coin_exchange/CoinExchange";
import CoinExchangeEdit from "./views/coin_exchange/CoinExchangeEdit";

import logo from "./logo.svg";
import "./App.css";

class App extends Component {
  constructor(props) {
    super(props);

    common.loadFromLocalStorage();
  }

  componentWillUpdate() {
    if (window.location.pathname !== "/login") this._checkLogin();
  }

  render() {
    return (
      <div className="h-100 px-0">
        <Switch>
          <Route exact path="/login" component={null} />
          <Route path="/" component={TopMenu} />
        </Switch>
        <Switch>
          <Route exact path="/" component={Administrator} />
          <Route exact path="/administrator" component={Administrator} />
          <Route
            exact
            path="/administrator/edit"
            component={AdministratorEdit}
          />
          <Route exact path="/bank" component={Bank} />
          <Route exact path="/bank/edit" component={BankEdit} />
          <Route
            exact
            path="/calculate/token-to-money"
            component={CalcTokenToMoney}
          />
          <Route
            exact
            path="/calculate/token-to-token"
            component={CalcTokenToToken}
          />
          <Route exact path="/exchanger" component={Exchanger} />
          <Route exact path="/exchanger/edit" component={ExchangerEdit} />
          <Route exact path="/faq" component={Faq} />
          <Route exact path="/faq/edit" component={FaqEdit} />
          <Route exact path="/global-vars" component={GlobalVars} />
          <Route exact path="/global-vars/edit" component={GlobalVarsEdit} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/notice" component={Notice} />
          <Route exact path="/notice/edit" component={NoticeEdit} />
          <Route exact path="/money-to-token" component={MoneyToToken} />
          <Route exact path="/statistics" component={Statistics} />
          <Route
            exact
            path="/superuser-transfer"
            component={SuperuserTransfer}
          />
          <Route exact path="/token-to-money" component={TokenToMoney} />
          <Route exact path="/token-to-token" component={TokenToToken} />
          <Route exact path="/transfer" component={Transfer} />
          <Route exact path="/user" component={User} />
          <Route exact path="/user/edit" component={UserEdit} />
          <Route exact path="/account" component={Account} />
          <Route exact path="/account/edit" component={AccountEdit} />

          <Route exact path="/coin-exchange" component={CoinExchange} />
          <Route
            exact
            path="/coin-exchange/edit"
            component={CoinExchangeEdit}
          />
        </Switch>
        <ToastContainer />
      </div>
    );
  }

  async _checkLogin() {
    common.isLoggedIn(() => {
      if (window.location.pathname !== "/login")
        this.props.history.push("/login");
    });
  }
}

export default App;
